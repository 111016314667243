import { useContext, useEffect, useState } from "react";
import { Navigate } from "react-router-dom";

import { useForm } from "react-hook-form";
import { FormContainer } from "react-hook-form-mui";

import { Button, Typography } from "@mui/material";
import { useStyles } from "./UpdateAncestryStyle";

import { ancestryList, iGetAncestry, useAncestry } from "../../remote/ancestry";
import { iFormDetails } from "../../remote/details";

import { ProfileContext } from "../../utilities/AuthProvider";

import { AncestryForm } from "../../components/AncestryForm/AncestryForm";
import { ContentLoading } from "../../components/ContentLoading/ContentLoading";

export const UpdateAncestry = () => {
    const classes = useStyles();

    const { isProfileLoading, profileRefresh } = useContext(ProfileContext);

    const formContext = useForm<iFormDetails>();

    const [isSaving, setSaving] = useState(false);
    const { handleSubmit, watch, reset } = formContext;
    const [isSendingComplete, setCompleteComplete] = useState(false);
    const { get, set } = useAncestry();
    const [prefilled, setPrefilled] = useState(false);

    useEffect(() => {
        if (!get.isLoading && !prefilled) {
            const ancestry: string[] = get.payload.map(
                (t: iGetAncestry) => t.name
            );
            let other = "";

            const data = {
                ...watch(),
                ancestry: ancestry
                    .filter((a) => {
                        if (!(ancestryList.indexOf(a) >= 0)) {
                            other = a;
                            return false;
                        }

                        return true;
                    })
                    .map((a) => a),
            };

            if (other) {
                data.other = ["1"];
                data.unOther = ["1"];
                data.otherValue = other;
            }

            reset(data);
            setPrefilled(true);
        }
    }, [get.isLoading, get.payload, prefilled, reset, watch]);

    useEffect(() => {
        if (isSaving && !set.isSaving) {
            profileRefresh();
            setCompleteComplete(true);
        }
    }, [isSaving, profileRefresh, set.isSaving]);

    const save = handleSubmit((data) => {
        let ancestry = [];

        if (data.otherValue) {
            ancestry.push(data.otherValue);
        }

        if (data.ancestry && !!data.ancestry.length) {
            ancestry = ancestry.concat(data.ancestry);
        }

        const payload = {
            ancestry: ancestry,
        };

        setSaving(true);
        set.save(payload);
    });

    if (isSendingComplete) return <Navigate to={"/profile"} />;
    if (isProfileLoading || get.isLoading) return <ContentLoading />;

    return (
        <>
            <Typography variant={"h1"}>Ancestry</Typography>
            <FormContainer
                FormProps={{ className: classes.form }}
                formContext={formContext}
                onSuccess={save}
            >
                <AncestryForm watch={watch} reset={reset} />
                <Typography className={classes.buttonBar}>
                    <Button
                        fullWidth
                        disabled={isSaving}
                        type={"submit"}
                        variant={"contained"}
                        color={"primary"}
                    >
                        {isSaving ? `Saving...` : `Save`}
                    </Button>
                </Typography>
            </FormContainer>
        </>
    );
};
