import { Box, Card, Link, Typography } from "@material-ui/core";
import PhoneIcon from "@material-ui/icons/Phone";
import EmailIcon from "@material-ui/icons/Email";
import { useStyles } from "./SupportCardStyle";

interface iProps {
    icon: string;
    label: string;
    contact: string;
    url?: string;
}

export const SupportCard = (props: iProps) => {
    const classes = useStyles();

    const { icon, label, contact, url } = props;

    let iconDisplay = undefined;
    switch (icon) {
        case "phone":
            iconDisplay = (
                <PhoneIcon color="secondary" style={{ fontSize: 45 }} />
            );
            break;

        case "email":
            iconDisplay = (
                <EmailIcon color="secondary" style={{ fontSize: 45 }} />
            );
    }

    return (
        <>
            <Link href={url}>
                <Card variant="outlined" className={classes.card}>
                    <Box m={1} textAlign="center">
                        {iconDisplay}
                        <Typography variant="overline">{label}</Typography>
                        <Typography variant="h4" style={{ lineHeight: 1.4 }}>
                            {contact}
                        </Typography>
                    </Box>
                </Card>
            </Link>
        </>
    );
};
