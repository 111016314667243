import clsx from "clsx";
import { DateTime } from "luxon";

import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Box,
    Chip,
    Grid,
    List,
    ListItem,
    ListItemText,
    Typography,
} from "@mui/material";

import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { useStyles } from "./BioBankStyle";

export const BioBank = () => {
    const classes = useStyles();

    // temporary
    const isPending = false;
    const positiveValue = 8;
    const negativeValue = -7;

    return (
        <Box mt={4}>
            <Typography variant="subtitle1">Biobank</Typography>
            <List className={classes.list}>
                <ListItem button divider>
                    <ListItemText
                        primary={
                            <Typography variant="subtitle1">
                                LVB #15483
                            </Typography>
                        }
                        secondary={
                            !isPending ? (
                                <Typography variant="overline">
                                    {DateTime.fromISO(
                                        "2022-09-02T20:10:26.206Z" as string
                                    ).toLocaleString(DateTime.DATE_FULL)}
                                </Typography>
                            ) : (
                                ``
                            )
                        }
                    />
                    <Chip
                        className={clsx(classes.chip, {
                            [classes.isChipSuccess]: !isPending,
                            [classes.isChipPending]: isPending,
                        })}
                        size="small"
                        label={!isPending ? "Deposit" : "Pending"}
                    />
                </ListItem>
                <ListItem button>
                    <Grid className={classes.grid} container spacing={2}>
                        <Grid item xs={4}>
                            <Typography variant="body1">Cell Vials</Typography>
                            <Typography
                                className={clsx(classes.chip, {
                                    [classes.positive]: positiveValue > 0,
                                    [classes.negative]: positiveValue < 0,
                                })}
                                variant="h2"
                            >
                                +{positiveValue}
                            </Typography>
                        </Grid>
                        <Grid item xs={4}>
                            <Typography variant="body1">
                                Plasma Vials
                            </Typography>
                            <Typography
                                className={clsx(classes.chip, {
                                    [classes.positive]: negativeValue > 0,
                                    [classes.negative]: negativeValue < 0,
                                })}
                                variant="h2"
                            >
                                {negativeValue}
                            </Typography>
                        </Grid>
                        <Grid item xs={4}>
                            <Typography variant="body1">DNA (μg)</Typography>
                            <Typography variant="h2">40.9</Typography>
                        </Grid>
                    </Grid>
                </ListItem>
            </List>
            <Box>
                <Accordion elevation={0}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                    >
                        <Typography
                            variant="subtitle2"
                            style={{ marginBottom: 0 }}
                        >
                            Tell me more about t48
                        </Typography>
                    </AccordionSummary>
                    <AccordionDetails style={{ flexDirection: "column" }}>
                        <Typography paragraph>
                            It's neat
                        </Typography>
                        <Typography variant="subtitle2" paragraph>
                            What does this mean?
                        </Typography>
                        <Typography>
                            We have an unbeatable product
                        </Typography>
                        <ul>
                            <li>
                                Amazing products.
                            </li>
                            <li>
                                Top Service.
                            </li>
                            <li>
                                Exceptional Team.
                            </li>
                        </ul>
                        <Typography paragraph>
                            You can't beat out products
                        </Typography>
                        <Typography variant="subtitle2" paragraph>
                            Your sample's safety & security
                        </Typography>
                        <Typography paragraph>
                            The site is secured and monitored around the clock!
                        </Typography>
                        <Typography paragraph>
                            You wont find a better option.
                        </Typography>
                    </AccordionDetails>
                </Accordion>
            </Box>
        </Box>
    );
};
