import { useState, useEffect, useCallback } from "react";
import { fromFetch } from "rxjs/fetch";
import { switchMap } from "rxjs/operators";
import { Subscription, Subject, of, Observable } from "rxjs";
import { useToken } from "../remote/token";
import { utility } from "../utilities/utility";

interface iSubject {
    subject: Subject<unknown>;
    type: string;
}

const subjects: iSubject[] = [];
let remote: Observable<Response> | undefined = undefined;

export interface iSetterConfig {
    type: string;
    endpoint: string;
    authenticate: boolean;
    list?: boolean; // Unused in setters at this time
    responseFilter?: (result: Response) => any;
}

export const useSetter = (defaults: any, config: iSetterConfig) => {
    const token = useToken();
    const [payload, setPayload] = useState(defaults);
    const [isSaving, setSaving] = useState<boolean>(true);
    const [error, setError] = useState(false);
    const [subscription] = useState<Subscription>();
    const gateway = process.env.REACT_APP_API_GATEWAY || "https://localhost/";
    const s = subjects.find((s) => s.type === config.type);
    if (!s) subjects.push({ type: config.type, subject: new Subject() });
    const subject = subjects.find((s) => s.type === config.type)!.subject;

    const save = useCallback(
        (payload) => {
            if (config.authenticate && !token) return;

            setSaving(true);

            remote = fromFetch(
                new Request(gateway.concat(config.endpoint), {
                    mode: "cors",
                    method: "POST",
                    headers: config.authenticate
                        ? new Headers({
                              Authorization: `Bearer `.concat(token || ``),
                          })
                        : undefined,
                    body: JSON.stringify(payload),
                })
            ).pipe(
                switchMap((response) => {
                    if (response.ok) {
                        return response.json();
                    } else {
                        setError(true);
                        return of({
                            error: true,
                            message: `Error ${response.status}`,
                        });
                    }
                })
            );

            remote.subscribe({
                next: (result) => {
                    if (result) {
                        let r: any = null;
                        if (config.responseFilter) {
                            r = config.responseFilter(result);
                        } else {
                            r = utility.normalizeData(result, defaults);
                        }
                        subject.next(r);
                    }
                },
                complete: () => setSaving(false),
            });
        },
        [config, defaults, gateway, token, subject]
    );

    useEffect(() => {
        const sub: Subscription = subject.subscribe(setPayload);

        return () => {
            if (typeof sub !== "undefined") {
                sub.unsubscribe();
            }
        };
    }, [subject]);

    useEffect(() => {
        return () => {
            if (typeof subscription !== "undefined") subscription.unsubscribe();
        };
    }, [subscription]);

    return { isSaving, payload, save, error };
};
