export interface iMenuList {
    label: string;
    divider?: boolean;
    link: string;
    visibility: {
        members: boolean;
        nonmembers: boolean;
    };
}

export const MenuList: iMenuList[] = [
    {
        label: "Overview",
        link: "/",
        visibility: {
            members: true,
            nonmembers: true,
        },
    },
    {
        label: "Results",
        link: `/results`,
        visibility: {
            members: true,
            nonmembers: false,
        },
    },
    {
        label: "Profile",
        link: `/profile`,
        visibility: {
            members: true,
            nonmembers: false,
        },
    },
];
