import { useState, useEffect, useCallback } from "react";
import { fromFetch } from "rxjs/fetch";
import { switchMap } from "rxjs/operators";
import { Subscription, Subject, of, Observable } from "rxjs";
import { useToken } from "./token";

interface iOwner {
    id?: string;
    firstName: string;
    lastName: string;
    email: string;
    phone?: string;
}

interface iPayment {
    id: string;
    amount: number;
    created: string;
}

interface iResponse {
    id: string;
}

export interface iAddress {
    address: string;
    city: string;
    postalCode: string;
    state: string;
}

export interface iTransaction {
    id?: string;
    items: any;
    ownerId: string;
    owner: iOwner;
    billingAddress: iAddress;
    shippingAddress: iAddress;
    purchaseDate?: string;
    payment: iPayment;
}

const addSubject = new Subject<iResponse>();
let remote: Observable<Response> | undefined = undefined;

export const useTransaction = () => {
    const token = useToken();
    const [isSubmitting, setSubmitting] = useState(false);
    const [response, setResponse] = useState<iResponse>({
        id: "",
    });

    const add = useCallback(
        (payload: iTransaction) => {
            if (!token) return;

            setSubmitting(true);

            remote = fromFetch(
                new Request(process.env.REACT_APP_API_GATEWAY + "transaction", {
                    mode: "cors",
                    method: "POST",
                    headers: new Headers({
                        Authorization: `Bearer ${token}`,
                    }),
                    body: JSON.stringify(payload),
                })
            ).pipe(
                switchMap((response) => {
                    if (response.ok) {
                        return response.json();
                    } else {
                        return of({
                            error: true,
                            message: `Error ${response.status}`,
                        });
                    }
                })
            );

            remote.subscribe({
                next: (result) => {
                    if (result) {
                        const r: any = result;
                        addSubject.next(r);
                    }
                },
                complete: () => setSubmitting(false),
            });
        },
        [token]
    );

    useEffect(() => {
        const addSub: Subscription = addSubject.subscribe(setResponse);

        return () => {
            if (typeof addSub !== "undefined") addSub.unsubscribe();
        };
    }, []);

    return {
        response,
        isTransactionSubmitting: isSubmitting,
        addTransaction: add,
    };
};
