import {
    Alert,
    Box,
    Grid,
    List,
    ListItem,
    ListItemText,
    Typography,
} from "@mui/material";
import { useStyles } from "./KitStatusStyle";

import CheckIcon from "@mui/icons-material/Check";
import LocalShippingIcon from "@mui/icons-material/LocalShipping";
import ScheduleIcon from "@mui/icons-material/Schedule";

export const KitStatus = () => {
    const classes = useStyles();

    return (
        <Box mt={2}>
            <Typography variant="subtitle1">Your Kit Status</Typography>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Alert
                        icon={<CheckIcon fontSize="inherit" />}
                        severity="success"
                    >
                        Your kit was delivered.
                    </Alert>
                </Grid>
                <Grid item xs={12}>
                    <Alert
                        icon={<ScheduleIcon fontSize="inherit" />}
                        severity="warning"
                    >
                        Your kit is awaiting shipment.
                    </Alert>
                </Grid>
                <Grid item xs={12}>
                    <Alert
                        icon={<LocalShippingIcon fontSize="inherit" />}
                        severity="info"
                    >
                        Your kit has shipped.
                    </Alert>
                </Grid>
                <Grid item xs={12}>
                    <List className={classes.awaiting}>
                        <ListItem button className={classes.listItem}>
                            <ListItemText
                                primary={
                                    <Typography variant="overline">
                                        Fedex Tracking Number
                                    </Typography>
                                }
                                secondary={
                                    <Typography variant="subtitle1">
                                        1239187923842098
                                    </Typography>
                                }
                            />
                            <ListItemText
                                className={classes.end}
                                primary={
                                    <Typography variant="overline">
                                        Status
                                    </Typography>
                                }
                                secondary={
                                    <Typography
                                        variant="subtitle1"
                                        color="secondary"
                                    >
                                        In Transit
                                    </Typography>
                                }
                            />
                        </ListItem>
                    </List>
                </Grid>
            </Grid>
        </Box>
    );
};
