import { AuthLoading } from "@heatherstoneio/heatherstone-react-common";
import { createContext } from "react";
import { defaultProfile, useProfile } from "../remote/profile";
import { useAuthenticated } from "./useAuthenticated";

export const ProfileContext = createContext({
    isProfileLoading: false,
    profile: defaultProfile,
    profileRefresh: () => {},
});

export const AuthProvider = ({ children }: { children: JSX.Element }) => {
    const { isLoading, isAuthenticated } = useAuthenticated();

    const profile = useProfile();

    return (isLoading || (isAuthenticated && profile.get.isLoading)) &&
        !profile ? (
        <AuthLoading />
    ) : (
        <ProfileContext.Provider
            value={{
                isProfileLoading: profile.get.isLoading,
                profile: profile.get.payload,
                profileRefresh: profile.get.refresh,
            }}
        >
            {children}
        </ProfileContext.Provider>
    );
};
