import { Box, TextField, Typography, useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { useStyles } from "./StepContentStyle";

import { iProfile } from "../../remote/profile";
import { iSteps } from "../../utilities/steps";

export const StepContent = ({
    step,
    profile,
}: {
    step: iSteps;
    profile: iProfile;
}) => {
    const classes = useStyles();

    const theme = useTheme();
    const isMdDown = useMediaQuery(theme.breakpoints.down("md"));

    return (
        <>
            <Typography variant="h1">
                {step.pageTitle ? step.pageTitle : step.title}
            </Typography>
            {step.step === "review" ? (
                <>
                    <Typography paragraph>
                        Let's make sure you understand and consent to the
                        product t48 provides as part of your membership.
                    </Typography>
                    <Typography paragraph>
                        I, {profile.firstName} {profile.lastName}, request and
                        permit t48 to sign me up for their amazing service, as set forth below and in accordance with
                        t48's Terms of Service and Privacy Policy.
                    </Typography>
                    <Typography className={classes.bold} variant="h5">
                        {step.title}
                    </Typography>
                </>
            ) : (
                ``
            )}
            <Box>
                <TextField
                    className={classes.content}
                    disabled
                    multiline
                    fullWidth
                    rows={4}
                    value={step.content}
                    variant={isMdDown ? "filled" : "outlined"}
                />
            </Box>
        </>
    );
};
