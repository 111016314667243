import { useContext, useEffect, useState } from "react";
import { Navigate, useLocation } from "react-router-dom";

import { useForm } from "react-hook-form";
import { FormContainer, TextFieldElement } from "react-hook-form-mui";

import { iVerifyOTP, useOTP } from "../../remote/otp";

import { ProfileContext } from "../../utilities/AuthProvider";
import { defaultPhoneNumberDisplay } from "../../utilities/phone";

import { CustomNumericTextField } from "../../components/CustomNumericTextField/CustomNumericTextField";

import { Typography, Box, Button, useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { useStyles } from "./VerifyOneTimePasscodeStyle";

export const VerifyOneTimePasscode = () => {
    const classes = useStyles();

    const theme = useTheme();
    const isMdDown = useMediaQuery(theme.breakpoints.down("md"));

    const { isProfileLoading, profile, profileRefresh } =
        useContext(ProfileContext);
    const formContext = useForm<iVerifyOTP>();
    const { handleSubmit } = formContext;
    const [isSending, setSending] = useState(false);
    const [isSendingComplete, setSendingComplete] = useState(false);

    const qs = useLocation().search;
    const phone = new URLSearchParams(qs).get("phone");
    const decodedPhone = decodeURIComponent(phone || "");

    const { verify } = useOTP();

    useEffect(() => {
        if (isSending && !verify.isSaving) {
            profileRefresh();
            setSendingComplete(true);
        }
    }, [isSending, profileRefresh, verify.isSaving]);

    const save = handleSubmit((data) => {
        setSending(true);
        verify.save(data);
    });

    if (isSendingComplete) {
        if (!isProfileLoading && profile.kitReadiness.kitReady)
            return <Navigate to={"/profile"} />;

        return <Navigate to={"/"} />;
    }

    return (
        <>
            <Typography variant={"h1"}>Verify Phone Number</Typography>
            <Typography component="div">
                We sent a one-time passcode to{" "}
                <Box fontWeight="600" display="inline">
                    {phone
                        ? defaultPhoneNumberDisplay(decodedPhone)
                        : "your phone number"}
                </Box>
                . Please enter it to save this phone number to your account.
            </Typography>
            <FormContainer formContext={formContext} onSuccess={save}>
                <Box className={classes.fieldBox}>
                    <TextFieldElement
                        fullWidth
                        name="otp"
                        label="One-Time Passcode"
                        required
                        variant={isMdDown ? "filled" : "outlined"}
                        InputProps={{
                            inputComponent: CustomNumericTextField as any,
                            inputProps: {
                                format: "######",
                            },
                        }}
                    />
                </Box>
                <Typography className={classes.buttonBar}>
                    <Button
                        disabled={isSending}
                        type={"submit"}
                        variant={"contained"}
                        color={"secondary"}
                    >
                        {isSending ? `Processing...` : `Verify & save`}
                    </Button>
                </Typography>
            </FormContainer>
        </>
    );
};
