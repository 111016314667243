import { useContext, useEffect, useState } from "react";

import { useForm } from "react-hook-form";
import {
    FormContainer,
    RadioButtonGroup,
    TextFieldElement,
} from "react-hook-form-mui";
import { Navigate } from "react-router-dom";

import { useStyles } from "./CompleteDetailsStyle";
import { Box, Button, Typography, useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/material/styles";

import { iDetails, iFormDetails, useDetails } from "../../remote/details";

import { ProfileContext } from "../../utilities/AuthProvider";

import clsx from "clsx";
import { DateTime } from "luxon";

import { AncestryForm } from "../../components/AncestryForm/AncestryForm";
import { ContentLoading } from "../../components/ContentLoading/ContentLoading";
import { CustomDateTextField } from "../../components/CustomDateTextField/CustomDateTextField";

const isTooYoung = (date: string) =>
    DateTime.fromISO(date).diffNow("years").years > -18;

export const CompleteDetails = () => {
    const classes = useStyles();

    const theme = useTheme();
    const isMdDown = useMediaQuery(theme.breakpoints.down("md"));

    const { isProfileLoading, profile, profileRefresh } =
        useContext(ProfileContext);
    const [isSaving, setSaving] = useState(false);
    const formContext = useForm<iFormDetails>();
    const { handleSubmit, watch, reset } = formContext;
    const [error, setError] = useState(false);
    const [isSendingComplete, setCompleteComplete] = useState(false);
    const { set } = useDetails();

    useEffect(() => {
        if (isSaving && !set.isSaving) {
            profileRefresh();
            setCompleteComplete(true);
        }
    }, [isSaving, profileRefresh, set.isSaving]);

    const save = handleSubmit((data) => {
        const birthDate = data.year + "-" + data.month + "-" + data.day;

        setError(false);

        if (isTooYoung(birthDate)) {
            return setError(true);
        }

        let ancestry = [];

        if (data.otherValue) {
            ancestry.push(data.otherValue);
        }

        if (data.ancestry && !!data.ancestry.length) {
            ancestry = ancestry.concat(data.ancestry);
        }

        const payload: iDetails = {
            sex: data.sex,
            birthDate: birthDate,
            ancestry: ancestry,
        };

        setSaving(true);
        set.save(payload);
    });

    if (isSendingComplete) return <Navigate to={"/"} />;
    if (!isProfileLoading && profile.kitReadiness.profileComplete)
        return <Navigate to={"/"} />;
    if (isProfileLoading) return <ContentLoading />;

    return (
        <>
            <Typography variant={"h1"}>
                Complete your profile details
            </Typography>
            <Typography paragraph>
                We use this to order your testing. All fields are required.
            </Typography>
            <FormContainer
                FormProps={{ className: classes.form }}
                formContext={formContext}
                onSuccess={save}
            >
                <Box className={classes.fieldBox}>
                    <Typography variant="subtitle1">
                        Sex (Assigned at Birth)
                    </Typography>
                    <RadioButtonGroup
                        required
                        name="sex"
                        options={[
                            { id: "male", label: "Male" },
                            { id: "female", label: "Female" },
                        ]}
                    />
                </Box>
                <Typography variant="subtitle1">Birthdate</Typography>
                {error ? (
                    <Typography className={classes.error} variant="subtitle2">
                        Sorry, you must be over 18 to use t48.
                    </Typography>
                ) : (
                    ``
                )}
                <Box className={clsx(classes.threeColumn, classes.fieldBox)}>
                    <TextFieldElement
                        fullWidth
                        name="month"
                        label="Month"
                        required
                        variant={isMdDown ? "filled" : "outlined"}
                        InputProps={{
                            inputComponent: CustomDateTextField as any,
                            inputProps: {
                                month: true,
                            },
                        }}
                    />
                    <TextFieldElement
                        fullWidth
                        name="day"
                        label="Day"
                        required
                        variant={isMdDown ? "filled" : "outlined"}
                        InputProps={{
                            inputComponent: CustomDateTextField as any,
                            inputProps: {
                                day: true,
                            },
                        }}
                    />
                    <TextFieldElement
                        fullWidth
                        name="year"
                        label="Year"
                        required
                        variant={isMdDown ? "filled" : "outlined"}
                        InputProps={{
                            inputComponent: CustomDateTextField as any,
                            inputProps: {
                                year: true,
                            },
                        }}
                    />
                </Box>
                <Typography variant="subtitle1">Ancestry</Typography>
                <AncestryForm watch={watch} reset={reset} />
                <Typography className={classes.buttonBar}>
                    <Button
                        disabled={isSaving}
                        type={"submit"}
                        variant={"contained"}
                        color={"secondary"}
                    >
                        {isSaving ? `Saving...` : `Save`}
                    </Button>
                </Typography>
            </FormContainer>
        </>
    );
};
