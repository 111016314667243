import { Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles((theme: Theme) => ({
    bottomNav: {
        position: "fixed",
        bottom: 0,
        left: 0,
        width: `100vw`,
        backgroundColor: theme.palette.secondary.main,
    },
    bottomNavAction: {},
}));
