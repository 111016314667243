import { useContext, useEffect, useState } from "react";
import { Navigate } from "react-router-dom";

import {
    Box,
    Button,
    Checkbox,
    FormControlLabel,
    FormGroup,
    LinearProgress,
    Typography,
} from "@mui/material";
import { useStyles } from "./InformedConsentStyle";

import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";

import { iSteps, steps } from "../../utilities/steps";
import { ProfileContext } from "../../utilities/AuthProvider";

import { StepContent } from "../StepContent/StepContent";
import { useConsent } from "../../remote/consent";

import { ContentLoading } from "../../components/ContentLoading/ContentLoading";

export const InformedConsent = () => {
    const classes = useStyles();

    const { isProfileLoading, profile, profileRefresh } =
        useContext(ProfileContext);
    const [idx, setIdx] = useState(0);
    const [step, setStep] = useState<iSteps>(steps[idx]);
    const [error, setError] = useState(false);
    const [checked, setChecked] = useState(false);

    const [isSending, setSending] = useState(false);
    const [isSendingComplete, setSendingComplete] = useState(false);

    const { set } = useConsent();

    useEffect(() => {
        setStep(steps[idx]);
    }, [idx]);

    useEffect(() => {
        if (isSending && !set.isSaving) {
            profileRefresh();
            setSendingComplete(true);
        }
    }, [isSending, profileRefresh, set.isSaving]);

    const handleCheckBoxChange = () => {
        setChecked(!checked);
    };

    const onClick = () => {
        if (!checked) return setError(true);

        if (idx === steps.length - 1) {
            setSending(true);
            return set.save({});
        }

        setChecked(false);
        setIdx(idx + 1);
        setError(false);
    };

    const back = () => {
        setChecked(false);
        setIdx(idx - 1);
    };

    if (isSendingComplete) return <Navigate to={"/"} />;
    if (isProfileLoading) return <ContentLoading />;

    return (
        <>
            <Box py={2.5}>
                <LinearProgress variant="determinate" value={step.value} />
            </Box>
            <StepContent step={step} profile={profile} />
            <Box>
                <FormGroup row>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={checked}
                                onChange={handleCheckBoxChange}
                                color="primary"
                            />
                        }
                        label={`I approve the ${step.title}.`}
                    />
                </FormGroup>
                {error ? (
                    <Typography
                        className={classes.error}
                        variant="subtitle2"
                        paragraph
                    >
                        We require your approval to the {step.title} to proceed
                        with the testing included in your membership.
                    </Typography>
                ) : (
                    ``
                )}
                <Box py={2.5}>
                    <Button
                        variant="contained"
                        color="secondary"
                        onClick={onClick}
                        disabled={isSending}
                    >
                        {isSending ? `Processing` : `Save & Continue`}
                    </Button>
                </Box>
            </Box>
            {idx !== 0 ? (
                <Box mt={4} textAlign="center">
                    <Button
                        className={classes.button}
                        size="small"
                        startIcon={<ArrowBackIosIcon />}
                        onClick={back}
                        disabled={isSending}
                    >
                        {/* <Typography variant="subtitle2"> */}
                        Back to {steps[idx - 1].title}
                        {/* </Typography> */}
                    </Button>
                </Box>
            ) : (
                ``
            )}
        </>
    );
};
