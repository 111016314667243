import { makeStyles } from "@mui/styles";
import { Theme } from "@mui/material";

export const useStyles = makeStyles((theme: Theme) => ({
    bold: {
        fontWeight: 600,
    },
    content: {
        padding: 0,
        margin: theme.spacing(2, 0),
        "& .MuiInputBase-root.Mui-disabled": {
            color: "#000000",
        },
    },
    noMargin: {
        margin: 0,
    },
}));
