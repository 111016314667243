import { Button } from "@material-ui/core";
import { useContext } from "react";
import { ContentLoading } from "../components/ContentLoading/ContentLoading";
import { useVerification } from "../remote/verification";
import { ProfileContext } from "../utilities/AuthProvider";

export const SampleSendVerification = () => {
    const { isProfileLoading, profile } = useContext(ProfileContext);

    const { send } = useVerification();

    const onClick = () => {
        send.save({ id: profile.id });
    };

    console.log(!send.isSaving);

    if (isProfileLoading) return <ContentLoading />;

    return (
        <>
            <Button
                disabled={!send.isSaving}
                variant="contained"
                color="primary"
                onClick={onClick}
            >
                Send email verification
            </Button>
        </>
    );
};
