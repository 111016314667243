import { useContext, useState } from "react";
import { NavLink as RouterLink } from "react-router-dom";

import {
    AppBar,
    Box,
    Button,
    IconButton,
    Link,
    Menu,
    MenuItem,
    Toolbar,
    useMediaQuery,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";

import { useStyles } from "./CustomAppBarStyles";
import { ReactComponent as Logo } from "../../logo.svg";
import { ProfileContext } from "../../utilities/AuthProvider";

interface iProps {
    handleUserLogout: () => void;
    setMobileOpen: (value: boolean) => void;
}

export const CustomAppBar = ({ handleUserLogout, setMobileOpen }: iProps) => {
    const { isProfileLoading, profile } = useContext(ProfileContext);

    const classes = useStyles();
    const theme = useTheme();
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const isMenuOpen = Boolean(anchorEl);
    const isLarger = useMediaQuery(theme.breakpoints.up("sm"));

    const handleMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
    };

    const renderMenu = (
        <Menu
            anchorEl={anchorEl}
            anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
            id="primary-menu"
            keepMounted
            transformOrigin={{ vertical: "top", horizontal: "right" }}
            open={isMenuOpen}
            onClose={handleMenuClose}
        >
            <div>
                {profile.kitReadiness.kitReady ? (
                    <>
                        <MenuItem
                            component={RouterLink}
                            to={`/membership`}
                            onClick={() => handleMenuClose()}
                        >
                            <Box ml={1} />
                            Membership
                        </MenuItem>
                        <MenuItem
                            component={RouterLink}
                            to={`/profile`}
                            onClick={() => handleMenuClose()}
                            divider
                        >
                            <Box ml={1} />
                            Profile
                        </MenuItem>
                    </>
                ) : (
                    ``
                )}
                <MenuItem
                    onClick={() => {
                        handleMenuClose();
                        handleUserLogout();
                    }}
                >
                    <Box ml={1} />
                    Log out
                </MenuItem>
            </div>
        </Menu>
    );

    return (
        <AppBar position="fixed" className={classes.appBar}>
            <Toolbar className={classes.toolbar}>
                <div className={classes.grow}>
                    <Link component={RouterLink} to={`/`}>
                        <Logo height={`30px`} />
                    </Link>
                </div>
                <div className={classes.sectionDesktop}></div>
                <div className={classes.grow} />
                <div className={classes.sectionDesktop}>
                    {!isProfileLoading &&
                    profile.membershipStatus === "active" ? (
                        <>
                            {isLarger ? (
                                <Button
                                    component={RouterLink}
                                    to={`/`}
                                    color={"inherit"}
                                    style={{ minWidth: 120 }}
                                >
                                    Dashboard
                                </Button>
                            ) : (
                                ``
                            )}
                            <IconButton
                                aria-label="account of current user"
                                aria-controls="primary-search-account-menu"
                                aria-haspopup="true"
                                color="inherit"
                                onClick={handleMenuOpen}
                            >
                                <AccountCircleIcon />
                            </IconButton>
                        </>
                    ) : (
                        <></>
                    )}
                </div>
            </Toolbar>
            {renderMenu}
        </AppBar>
    );
};
