import { Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles((theme: Theme) => ({
    awaiting: {
        border: `1px solid ${theme.palette.divider}`,
        borderRadius: theme.shape.borderRadius,
    },
    delivered: {
        border: `1px solid ${theme.palette.info.dark}`,
        color: theme.palette.info.dark,
        backgroundColor: theme.palette.info.light,
        borderRadius: theme.shape.borderRadius,
    },
    success: {
        border: `1px solid ${theme.palette.success.dark}`,
        color: theme.palette.success.dark,
        backgroundColor: theme.palette.success.light,
        borderRadius: theme.shape.borderRadius,
    },
    end: {
        textAlign: "end",
    },
    listItem: {
        paddingTop: 0,
        paddingBottom: 0,
    },
}));
