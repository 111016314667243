import { Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles((theme: Theme) => ({
    card: {
        marginBottom: theme.spacing(2),
        backgroundColor: "#F4F0EE",
        borderColor: "#EDE6E1",
        padding: "2rem 1rem",
    },
    typography: {
        subtitle1: {
            fontSize: "5px",
        },
    },
}));
