import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles(() => ({
    fieldBox: {
        marginBottom: "1rem",
    },
    inlineDisplay: {
        display: "inline-display",
    },
}));
