import { useState, useEffect, useCallback } from "react";
import { fromFetch } from "rxjs/fetch";
import { switchMap } from "rxjs/operators";
import { Subscription, Subject, of, Observable } from "rxjs";
import { useToken } from "../remote/token";
import { utility } from "./utility";

interface iSubject {
    subject: Subject<unknown>;
    type: string;
}

const subjects: iSubject[] = [];
let remote: Observable<Response> | undefined = undefined;

export interface iGetterConfig {
    type: string;
    endpoint: string;
    authenticate: boolean;
    list: boolean;
    responseFilter?: (result: Response) => any;
}

export const useGetter = (defaults: any, config: iGetterConfig) => {
    const token = useToken();

    const [payload, setPayload] = useState(config.list ? [] : defaults);
    const [isLoading, setLoading] = useState<boolean>(true);
    const [initialized, setInitialized] = useState<boolean>(false);
    const [subscription] = useState<Subscription>();
    const gateway = process.env.REACT_APP_API_GATEWAY || "https://localhost/";
    const s = subjects.find((s) => s.type === config.type);
    if (!s) subjects.push({ type: config.type, subject: new Subject() });
    const subject = subjects.find((s) => s.type === config.type)!.subject;

    const refresh = useCallback(() => {
        if (config.authenticate && !token) return;

        setLoading(true);

        remote = fromFetch(
            new Request(gateway.concat(config.endpoint), {
                mode: "cors",
                method: "GET",
                headers: config.authenticate
                    ? new Headers({
                          Authorization: `Bearer `.concat(token || ``),
                      })
                    : undefined,
            })
        ).pipe(
            switchMap((response) => {
                if (response.ok) {
                    return response.json();
                } else {
                    return of({
                        error: true,
                        message: `Error ${response.status}`,
                    });
                }
            })
        );

        remote.subscribe({
            next: (result) => {
                if (result) {
                    let r: any = null;
                    if (config.responseFilter) {
                        r = config.responseFilter(result);
                    } else {
                        if (config.list) {
                            if (!result || !Array.isArray(result)) {
                                r = [];
                            } else {
                                r = result.map((item) =>
                                    utility.normalizeData(item, defaults)
                                );
                            }
                        } else {
                            r = utility.normalizeData(result, defaults);
                        }
                    }
                    subject.next(r);
                }
            },
            complete: () => setLoading(false),
        });
    }, [config, defaults, gateway, token, subject]);

    useEffect(() => {
        const sub: Subscription = subject.subscribe(setPayload);

        return () => {
            if (typeof sub !== "undefined") {
                sub.unsubscribe();
                setInitialized(false);
            }
        };
    }, [subject]);

    useEffect(() => {
        if (
            ((config.authenticate && token) || !config.authenticate) &&
            !initialized
        ) {
            setInitialized(true);
            refresh();
        }
    }, [initialized, refresh, token, config]);

    useEffect(() => {
        return () => {
            if (typeof subscription !== "undefined") subscription.unsubscribe();
        };
    }, [subscription]);

    return { isLoading, payload, refresh };
};
