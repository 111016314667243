import { Navigate, NavLink as RouterLink } from "react-router-dom";
import { useContext } from "react";

import {
    Box,
    Chip,
    List,
    ListItem,
    ListItemText,
    Typography,
} from "@mui/material";
import { useStyles } from "./MembershipStyle";

import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";

import { ProfileContext } from "../../utilities/AuthProvider";
import { ContentLoading } from "../../components/ContentLoading/ContentLoading";
import { iMemberships, useMemberships } from "../../remote/memberships";

import clsx from "clsx";
import { DateTime } from "luxon";

export const Membership = () => {
    const classes = useStyles();

    const { isProfileLoading, profile } = useContext(ProfileContext);
    const { get } = useMemberships();

    if (!isProfileLoading && !profile.kitReadiness.kitReady)
        return <Navigate to={"/"} />;
    if (!isProfileLoading && profile.membershipStatus !== "active")
        return <Navigate to={"/checkout-shipping"} />;
    if (isProfileLoading || get.isLoading) return <ContentLoading />;

    return (
        <>
            <Typography variant={"h1"}>Your Membership</Typography>
            <Box mt={2}>
                <Chip
                    className={clsx(classes.chip, {
                        [classes.isChipSuccess]:
                            profile.membershipStatus === "active",
                        [classes.isChipWarning]:
                            profile.membershipStatus !== "active",
                    })}
                    size="small"
                    label={
                        profile.membershipStatus === "active"
                            ? "Active"
                            : "Inactive"
                    }
                />
                <Typography variant="subtitle1" display="inline">
                    since {new Date(profile.memberSince).getFullYear()}
                </Typography>
            </Box>
            <Box mt={4}>
                <Typography variant="subtitle2">ORDER HISTORY</Typography>
                <List>
                    {get.payload.map((p: iMemberships) => (
                        <ListItem
                            button
                            className={classes.listItem}
                            key={p.id}
                            component={RouterLink}
                            to={`/order/${p.transactionId}`}
                        >
                            <ListItemText
                                primary={
                                    <Typography variant="subtitle1">
                                        t48 Membership
                                    </Typography>
                                }
                                secondary={DateTime.fromISO(
                                    p.expiration as string
                                ).toLocaleString(DateTime.DATE_FULL)}
                            />
                            <ArrowForwardIosIcon />
                        </ListItem>
                    ))}
                </List>
            </Box>
        </>
    );
};
