import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles(() => ({
    item: {
        padding: 0,
    },
    table: {
        "& .MuiTableCell-root": {
            borderBottomWidth: 0,
            verticalAlign: "top",
        },
        "& .MuiTableCell-root:last-child": {
            textAlign: "right",
        },
        "& .MuiTableRow-root:last-child .MuiTableCell-root": {
            fontWeight: "700",
            borderTop: "2px solid rgba(224, 224, 224, 1)",
        },
    },
}));
