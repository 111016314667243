import { useContext } from "react";
import { Navigate } from "react-router-dom";

import { Box, LinearProgress, Typography } from "@mui/material";

import { ProfileContext } from "../../utilities/AuthProvider";

import { ContentLoading } from "../../components/ContentLoading/ContentLoading";
import { CartCard } from "../../components/Cards/CartCard/CartCard";
import { StripePaymentForm } from "../../components/StripePaymentForm/StripePaymentForm";

export const CheckoutPayment = () => {
    const { isProfileLoading, profile } = useContext(ProfileContext);

    if (!isProfileLoading && profile.membershipStatus === "active")
        return <Navigate to={"/"} />;
    if (isProfileLoading) return <ContentLoading />;

    return (
        <>
            <Box py={2.5}>
                <LinearProgress variant="determinate" value={95} />
            </Box>
            <Box pb={2}>
                <Typography variant="h1">Checkout</Typography>
                <Typography variant="subtitle1" paragraph>
                    2/2 Add your payment information
                </Typography>
            </Box>
            <StripePaymentForm />
            <Box py={2.5}>
                <CartCard />
            </Box>
        </>
    );
};
