import { makeStyles } from "@mui/styles";
import { Theme } from "@mui/material";

export const useStyles = makeStyles((theme: Theme) => ({
    card: {
        marginBottom: theme.spacing(2),
        backgroundColor: "#F4F0EE",
    },
    completed: {
        backgroundColor: "#CEF4EA",
        "& .MuiAvatar-root": {
            backgroundColor: "#3FC4A4",
        },
        "& .MuiCardHeader-title": {
            textDecoration: "line-through",
        },
    },
    link: {
        textDecoration: "underline",
    },
}));
