import { useGetter } from "../utilities/getter";
import { useSetter } from "../utilities/setter";

export interface iMemberships {
    id: string;
    status: string;
    expiration: string;
    term: string;
    transactionId: string;
    ownerId: string;
    gift: boolean;
    recipientEmail: string;
    start: string;
    type: string;
}

export const defaultMembership: iMemberships = {
    id: "",
    status: "",
    expiration: "",
    term: "",
    transactionId: "",
    ownerId: "",
    gift: false,
    recipientEmail: "",
    start: "",
    type: "",
};

export const useMemberships = () => {
    const config = {
        type: "memberships",
        endpoint: `memberships`,
        authenticate: true,
        list: true,
    };

    const getter = useGetter(defaultMembership, config);
    const setter = useSetter(defaultMembership, config);

    return {
        get: getter,
        set: setter,
    };
};
