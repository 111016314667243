import { useGetter } from '../utilities/getter';

export interface iProduct {
    id: string;
    name: string;
    description: string;
    price: number;
    type: string;
    membershipType: string;
    term: number;
}

export const defaultProduct: iProduct = {
    id: '',
    name: '',
    description: '',
    price: 0,
    type: '',
    membershipType: '',
    term: 0
};

export const useProducts = () => {
    const config = {
        type: 'products',
        endpoint: `products`,
        authenticate: false,
        list: true
    };

    const getter = useGetter(defaultProduct, config);

    return {
        get: getter
    };
};
