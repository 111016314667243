import { useContext } from "react";
import { Navigate } from "react-router-dom";

import { ProfileCard } from "../../components/Cards/ProfileCard/ProfileCard";
import { ContentLoading } from "../../components/ContentLoading/ContentLoading";

import { stringToColor } from "../../utilities/color";
import { ProfileContext } from "../../utilities/AuthProvider";
import { defaultPhoneNumberDisplay } from "../../utilities/phone";

import { Avatar, Box, capitalize, Typography } from "@mui/material";
import { useStyles } from "./ProfileStyle";

export const Profile = () => {
    const classes = useStyles();

    const { isProfileLoading, profile } = useContext(ProfileContext);

    if (!isProfileLoading && !profile.kitReadiness.kitReady)
        return <Navigate to={"/"} />;
    if (!isProfileLoading && profile.membershipStatus !== "active")
        return <Navigate to={"/checkout-shipping"} />;
    if (isProfileLoading) return <ContentLoading />;

    return (
        <>
            <Typography variant={"h1"}>Profile</Typography>
            <Box my={2} className={classes.imageContainer}>
                <Avatar
                    className={classes.profileImage}
                    style={{
                        backgroundColor: stringToColor(profile.firstName),
                    }}
                >
                    <Typography variant="h1">
                        {profile.firstName.substring(1, 0)}
                    </Typography>
                </Avatar>
            </Box>
            <Box textAlign="center">
                <Typography variant={"h5"}>
                    {profile.firstName + " " + profile.lastName}
                </Typography>
            </Box>
            <ProfileCard
                hasChip
                chipType={
                    profile.kitReadiness.emailVerified ? "success" : "warning"
                }
                chipTitle={
                    profile.kitReadiness.emailVerified
                        ? "Verified"
                        : "Unverified"
                }
                divider
                title="Email Address"
                value={profile.email}
            />
            <ProfileCard
                divider
                title="Password"
                value="••••••••••••••••••••••••••••"
            />
            <ProfileCard
                editable
                divider
                title="Address"
                value={profile.shippingAddress}
                link="/update-address"
            />
            <ProfileCard
                editable
                divider
                title="Phone Number"
                value={defaultPhoneNumberDisplay(profile.phoneNumber)}
                link="/update-phone-number"
            />
            <ProfileCard
                divider
                isDate
                title="Birthdate"
                value={profile.birthDate}
            />
            <ProfileCard
                divider
                title="Sex (Assigned at Birth)"
                value={capitalize(profile.sex)}
            />
            <ProfileCard
                editable
                divider
                title="Ancestry"
                value={profile.ancestry}
                link="/update-ancestry"
            />
        </>
    );
};
