import { Auth } from "aws-amplify";
import { useEffect, useState } from "react";

export const useAuthenticated = () => {
    const [isLoading, setLoading] = useState(false);
    const [isAuthenticated, setIsAuthenticated] = useState(false);

    useEffect(() => {
        const getAuthenticatedUser = async () => {
            setLoading(true);

            try {
                await Auth.currentAuthenticatedUser();
                setIsAuthenticated(true);
            } catch {
                setIsAuthenticated(false);
            }

            setLoading(false);
        };

        getAuthenticatedUser();
    }, []);

    return { isLoading, isAuthenticated };
};
