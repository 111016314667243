import { useState, useEffect } from "react";

import {
    Card,
    CardContent,
    Table,
    TableBody,
    TableCell,
    TableRow,
    Typography,
} from "@mui/material";

import NumberFormat from "react-number-format";

import { useCart } from "../../../remote/cart";
import { useTaxRate } from "../../../remote/taxrate";
import { useShipping } from "../../../remote/shipping";
import { useProducts, iProduct } from "../../../remote/products";
import { useStyles } from "./CartCardStyle";

export const CartCard = () => {
    const cart = useCart();
    const products = useProducts();
    const shipping = useShipping();
    const taxRate = useTaxRate(
        shipping.get?.payload?.shippingAddress?.postalCode
    );
    const [zip, setZip] = useState(
        shipping.get?.payload?.shippingAddress?.postalCode
    );
    const taxDisplay = taxRate.get.isLoading ? (
        "TBD"
    ) : (
        <NumberFormat
            value={cart.contents.totals.tax}
            displayType={"text"}
            fixedDecimalScale
            decimalScale={2}
            thousandSeparator={true}
            prefix={"$"}
        />
    );
    const taxLabel = taxRate.get.isLoading
        ? ""
        : `(`.concat(taxRate.get.payload.state, ` rate)`);
    const totalDisplay = (
        <NumberFormat
            value={cart.contents.totals.final}
            displayType={"text"}
            fixedDecimalScale
            decimalScale={2}
            thousandSeparator={true}
            prefix={"$"}
        />
    );
    const classes = useStyles();

    useEffect(() => {
        if (products.get.isLoading) return;
        const feeProduct = products.get.payload.find(
            (p: iProduct) => p.type === "fee"
        );
        const membershipProduct = products.get.payload.find(
            (p: iProduct) => p.type === "membership"
        );
        const feeCart = cart.contents.items.find(
            (i) => i.item.id === feeProduct.id
        );
        const membershipCart = cart.contents.items.find(
            (i) => i.item.id === membershipProduct.id
        );
        if (!feeCart) {
            cart.update({
                item: {
                    id: feeProduct.id,
                    name: feeProduct.name,
                    description: feeProduct.description,
                    price: feeProduct.price,
                    type: feeProduct.type,
                },
                quantity: 1,
                gift: false,
            });
        }
        if (!membershipCart) {
            cart.update({
                item: {
                    id: membershipProduct.id,
                    name: membershipProduct.name,
                    description: membershipProduct.description,
                    price: membershipProduct.price,
                    type: membershipProduct.type,
                    term: membershipProduct.term,
                    membershipType: membershipProduct.membershipType,
                },
                quantity: 1,
                gift: false,
            });
        }
    }, [cart, products]);

    useEffect(() => {
        const pc = shipping.get?.payload?.shippingAddress?.postalCode;
        if (zip !== pc) {
            setZip(pc);
            taxRate.get.refresh();
        }
    }, [shipping, taxRate, zip]);

    useEffect(() => {
        if (taxRate.get.isLoading) return;
        if (cart.contents.taxRate !== taxRate.get.payload.tax) {
            cart.setTaxRate(taxRate.get.payload.tax);
        }
    }, [cart, taxRate]);

    return (
        <>
            <Card variant="outlined">
                <CardContent>
                    <Typography
                        className={classes.cardTitle}
                        color="textSecondary"
                        gutterBottom
                    >
                        Order Summary
                    </Typography>
                    <Typography variant="h5" component="h2">
                        t48 Membership
                    </Typography>
                    <Table size="small" className={classes.table}>
                        <TableBody>
                            {cart.contents.items.map((i) => (
                                <TableRow key={i.item.id}>
                                    <TableCell>{i.item.description}</TableCell>
                                    <TableCell>
                                        <NumberFormat
                                            value={i.item.price}
                                            displayType={"text"}
                                            fixedDecimalScale
                                            decimalScale={2}
                                            thousandSeparator={true}
                                            prefix={"$"}
                                        />
                                    </TableCell>
                                </TableRow>
                            ))}
                            <TableRow>
                                <TableCell>Sales tax {taxLabel}</TableCell>
                                <TableCell>{taxDisplay}</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>Shipping</TableCell>
                                <TableCell>free</TableCell>
                            </TableRow>
                            {/* <TableRow>
                            <TableCell><Link href="#">Add a coupon</Link></TableCell>
                            <TableCell></TableCell>
                        </TableRow> */}
                            <TableRow>
                                <TableCell>Total</TableCell>
                                <TableCell>{totalDisplay}</TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </CardContent>
            </Card>
        </>
    );
};
