import React from "react";
import NumberFormat, { NumberFormatProps } from "react-number-format";

const limit = (val: string, max: string) => {
    if (val.length === 1 && val[0] > max[0]) {
        val = "0" + val;
    }

    if (val.length === 2) {
        if (Number(val) === 0) {
            val = "01";
        } else if (val > max) {
            val = max;
        }
    }

    return val;
};

const dayFormat = (val: string) => {
    return limit(val.substring(0, 2), "31");
};

const monthFormat = (val: string) => {
    return limit(val.substring(0, 2), "12");
};

const yearFormat = (val: string) => {
    const currentYear = new Date().getFullYear().toString();
    return limit(val.substring(0, 4), currentYear);
};

interface CustomProps {
    onChange: (event: { target: { name: string; value: string } }) => void;
    name: string;
    day?: boolean;
    month?: boolean;
    year?: boolean;
}

export const CustomDateTextField = React.forwardRef<
    NumberFormatProps,
    CustomProps
>(function NumberFormatCustom(props, ref) {
    const { day, month, year, onChange, ...other } = props;

    return (
        <NumberFormat
            {...other}
            getInputRef={ref}
            onValueChange={(values) => {
                onChange({
                    target: {
                        name: props.name,
                        value: values.value,
                    },
                });
            }}
            format={
                day
                    ? dayFormat
                    : month
                    ? monthFormat
                    : year
                    ? yearFormat
                    : undefined
            }
            mask="D"
            placeholder={day ? "DD" : month ? "MM" : year ? "YYYY" : undefined}
        />
    );
});
