import { useContext, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Navigate } from "react-router-dom";
import {
    FormContainer,
    SelectElement,
    TextFieldElement,
} from "react-hook-form-mui";

import { Box, Button, Typography, useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { useStyles } from "./UpdateAddressStyle";

import { ProfileContext } from "../../utilities/AuthProvider";
import { iShipping, useShipping, usStates } from "../../remote/shipping";

import { ContentLoading } from "../../components/ContentLoading/ContentLoading";
import { CustomNumericTextField } from "../../components/CustomNumericTextField/CustomNumericTextField";

import clsx from "clsx";

export const UpdateAddress = () => {
    const classes = useStyles();

    const theme = useTheme();
    const isMdDown = useMediaQuery(theme.breakpoints.down("md"));

    const { isProfileLoading, profile, profileRefresh } =
        useContext(ProfileContext);
    const formContext = useForm<iShipping>();
    const { reset, handleSubmit } = formContext;
    const [isSending, setSending] = useState(false);
    const [isSendingComplete, setSendingComplete] = useState(false);

    const { get, set } = useShipping();
    const [prefilled, setPrefilled] = useState(false);

    useEffect(() => {
        if (!get.isLoading && !prefilled) {
            reset(get.payload);
            setPrefilled(true);
        }
    }, [get.isLoading, get.payload, prefilled, reset]);

    useEffect(() => {
        if (isSending && !set.isSaving) {
            profileRefresh();
            setSendingComplete(true);
        }
    }, [isSending, profileRefresh, set.isSaving]);

    const save = handleSubmit((data) => {
        setSending(true);

        const payload = {
            ...data,
            id: profile.id,
            firstName: profile.firstName,
            lastName: profile.lastName,
            phoneNumber: profile.phoneNumber,
        };

        set.save(payload);
    });

    if (isSendingComplete) {
        return <Navigate to={"/profile"} />;
    }

    if (isProfileLoading) return <ContentLoading />;

    return (
        <>
            <Typography variant={"h1"}>Update Address</Typography>
            <FormContainer
                FormProps={{ className: classes.form }}
                formContext={formContext}
                onSuccess={save}
            >
                <Box className={classes.fieldBox}>
                    <TextFieldElement
                        name="shippingAddress.address"
                        label="Street Address"
                        required
                        variant={isMdDown ? "filled" : "outlined"}
                    />
                </Box>
                <Box className={classes.fieldBox}>
                    <TextFieldElement
                        name="shippingAddress.city"
                        label="City"
                        required
                        variant={isMdDown ? "filled" : "outlined"}
                    />
                </Box>
                <Box className={clsx(classes.twoColumn, classes.fieldBox)}>
                    <SelectElement
                        options={usStates}
                        name="shippingAddress.state"
                        label="State"
                        required
                        variant={isMdDown ? "filled" : "outlined"}
                    />
                    <TextFieldElement
                        name="shippingAddress.postalCode"
                        label="Zip Code"
                        required
                        variant={isMdDown ? "filled" : "outlined"}
                        InputProps={{
                            inputComponent: CustomNumericTextField as any,
                            inputProps: {
                                format: "#########",
                            },
                        }}
                    />
                </Box>

                <Typography className={classes.buttonBar}>
                    <Button
                        fullWidth
                        disabled={isSending}
                        type={"submit"}
                        variant={"contained"}
                        color={"primary"}
                    >
                        {isSending ? `Processing...` : `Save`}
                    </Button>
                </Typography>
            </FormContainer>
        </>
    );
};
