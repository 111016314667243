import { useContext, SyntheticEvent, useEffect, useState } from "react";
import { loadStripe } from "@stripe/stripe-js";
import {
    CardCvcElement,
    CardExpiryElement,
    CardNumberElement,
    Elements,
    useElements,
    useStripe,
} from "@stripe/react-stripe-js";
import { StripeElementChangeEvent } from "@stripe/stripe-js";

import clsx from "clsx";
import { Navigate } from "react-router-dom";
import { DateTime } from "luxon";

import { ProfileContext } from "../../utilities/AuthProvider";
import { StripeTextField } from "@heatherstoneio/heatherstone-react-common";

import { useCart } from "../../remote/cart";
import { useShipping } from "../../remote/shipping";
import {
    iAddress,
    iTransaction,
    useTransaction,
} from "../../remote/transaction";

import { Alert, Box, Button, Snackbar } from "@mui/material";
import { useStyles } from "./StripePaymentFormStyle";

const stripePromise = loadStripe(
    process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY || "",
    {
        apiVersion: "2020-08-27",
    }
);

interface iLocalProps {
    disablePay?: boolean;
    thankYouInPlace?: boolean;
}

export interface iIntent {
    id: string;
    clientSecret: string;
}

export const StripePaymentForm = (props: iLocalProps) => {
    return (
        <Elements stripe={stripePromise}>
            <InnerForm {...props} />
        </Elements>
    );
};

const isCardError = (message: string): boolean =>
    message !== "untouched" && !!message;
const cardErrorText = (message: string): string =>
    message === "untouched" ? `` : message;

const InnerForm = (props: iLocalProps) => {
    const classes = useStyles();

    const { disablePay, thankYouInPlace } = props;

    const stripe = useStripe();
    const elements = useElements();
    const cart = useCart();
    const profile = useContext(ProfileContext);
    const shipping = useShipping();

    const amount = cart.contents.totals.final;
    const buyer = profile.profile;

    const [redirect, setRedirect] = useState(false);

    const [succeeded, setSucceeded] = useState(false);
    const [error, setError] = useState("");
    const [open, setOpen] = useState(false);
    const [processing, setProcessing] = useState(false);

    const [cardNumberError, setCardNumberError] = useState<string>("untouched");
    const [cardExpiryError, setCardExpiryError] = useState<string>("untouched");
    const [cardCvcError, setCardCvcError] = useState<string>("untouched");
    const [cardHasError, setCardHasError] = useState<boolean>(true);

    const { addTransaction, response: transactionResponse } = useTransaction();

    const MuiAlert = (props: any) => {
        return <Alert elevation={6} variant="filled" {...props} />;
    };

    useEffect(() => {
        setCardHasError(
            !!cardNumberError || !!cardExpiryError || !!cardCvcError
        );
    }, [cardNumberError, cardExpiryError, cardCvcError]);

    useEffect(() => {
        if (transactionResponse.id) {
            setSucceeded(true);
        }
    }, [transactionResponse.id]);

    const onCardChange = (event: StripeElementChangeEvent) => {
        switch (event.elementType) {
            case "cardNumber":
                setCardNumberError(event.error?.message || "");
                break;
            case "cardExpiry":
                setCardExpiryError(event.error?.message || "");
                break;
            case "cardCvc":
                setCardCvcError(event.error?.message || "");
                break;
        }
    };

    const handleSnackbarClose = (_: any, reason: string) => {
        if (reason === "clickaway") {
            return;
        }

        setOpen(false);
    };

    const handleSubmit = async (event: SyntheticEvent) => {
        event.preventDefault();

        setError("");
        setOpen(false);
        setProcessing(true);

        if (!stripe || !elements || disablePay) return;

        if (amount > 0) {
            const amountInt = Math.floor(amount * 100);
            const intentResponse = await window.fetch(
                new Request(process.env.REACT_APP_API_GATEWAY + "intent", {
                    mode: "cors",
                    method: "POST",
                    body: JSON.stringify({ id: "", amount: amountInt }),
                })
            );

            const data: iIntent = await intentResponse.json();

            const payload = await stripe.confirmCardPayment(
                data.clientSecret || "",
                {
                    payment_method: {
                        card: elements.getElement(CardNumberElement)!,
                    },
                }
            );

            if (payload.error) {
                setError(
                    payload.error?.message || "Error! Something went wrong."
                );
                setOpen(true);
                setProcessing(false);
            } else {
                if (payload.paymentIntent && buyer) {
                    const created = DateTime.fromSeconds(
                        payload.paymentIntent.created
                    )
                        .toUTC()
                        .toISO();

                    const transaction: iTransaction = {
                        items: cart?.contents.items || [],
                        ownerId: buyer.id || "",
                        owner: {
                            id: buyer.id || "",
                            firstName: buyer.firstName || "",
                            lastName: buyer.lastName || "",
                            email: buyer.email || "",
                        },
                        payment: {
                            id: payload.paymentIntent.id,
                            amount: amount,
                            created: created,
                        },
                        shippingAddress: shipping.get?.payload
                            ?.shippingAddress as iAddress,
                        billingAddress: shipping.get?.payload
                            ?.shippingAddress as iAddress,
                    };

                    addTransaction(transaction);
                }
            }
        }
    };

    useEffect(() => {
        if (succeeded) {
            sessionStorage.setItem("transactionId", transactionResponse.id);
            sessionStorage.removeItem("cart");
            setRedirect(true);
        }
    }, [succeeded, profile, transactionResponse.id]);

    if (redirect) {
        return <Navigate to={"/"} />;
    }

    return (
        <>
            <Snackbar
                open={open}
                autoHideDuration={7000}
                onClose={handleSnackbarClose}
            >
                <MuiAlert onClose={handleSnackbarClose} severity="error">
                    {error}
                </MuiAlert>
            </Snackbar>
            {succeeded && thankYouInPlace ? (
                <>Success</>
            ) : (
                <form onSubmit={handleSubmit}>
                    {amount > 0 ? (
                        <>
                            <Box className={classes.fieldBox}>
                                <StripeTextField
                                    label="Card Number"
                                    inputProps={{
                                        options: {
                                            showIcon: true,
                                        },
                                    }}
                                    error={isCardError(cardNumberError)}
                                    helperText={cardErrorText(cardNumberError)}
                                    onChange={onCardChange}
                                    required
                                    stripeElement={CardNumberElement}
                                />
                            </Box>

                            <Box
                                className={clsx(
                                    classes.fieldBox,
                                    classes.twoColumn
                                )}
                            >
                                <StripeTextField
                                    label="Expiration"
                                    error={isCardError(cardExpiryError)}
                                    helperText={cardErrorText(cardExpiryError)}
                                    onChange={onCardChange}
                                    required
                                    stripeElement={CardExpiryElement}
                                />
                                <StripeTextField
                                    label="CVC"
                                    error={isCardError(cardCvcError)}
                                    helperText={cardErrorText(cardCvcError)}
                                    onChange={onCardChange}
                                    required
                                    stripeElement={CardCvcElement}
                                />
                            </Box>
                        </>
                    ) : (
                        ``
                    )}
                    <Box py={2}>
                        <Button
                            type="submit"
                            disabled={
                                disablePay ||
                                processing ||
                                ((!stripe || cardHasError) && amount > 0)
                            }
                            color="secondary"
                            variant="contained"
                        >
                            {processing ? `Processing...` : <>Place Order</>}
                        </Button>
                    </Box>
                </form>
            )}
        </>
    );
};
