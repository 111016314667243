import { useEffect, useState } from "react";
import { Subject, Subscription } from "rxjs";

export interface iCartProduct {
    id: string;
    name: string;
    description: string;
    price: number;
    type: string;
    term?: number;
    membershipType?: number;
    nonTaxable?: boolean;
}

export interface iCartItem {
    item: iCartProduct;
    quantity: number;
    totals?: {
        base: number;
        tax: number;
        discount: number;
        line: number;
    },
    gift?: boolean;
}

export interface iCartContents {
    items: iCartItem[];
    totals: {
        base: number;
        tax: number;
        discount: number;
        final: number;
    },
    postalCode?: string;
    taxRate: number;
}

const calcLineTotals = (line: iCartItem, taxRate: number) => {
    const output = {
        base: line.item.price * line.quantity,
        tax: 0,
        discount: 0,
        line: 0
    }

    output.tax = line.item.nonTaxable ? 0 : parseFloat((output.base * (taxRate / 100)).toFixed(2));
    output.line = output.base + output.tax;

    return output;
}

const calcCartTotals = (cart: iCartContents) => {
    return cart.items.reduce((prev, next) => {
        next.totals = calcLineTotals(next, cart.taxRate);
        return {
            base: prev.base + (next.totals?.base || 0),
            tax: prev.tax + (next.totals?.tax || 0),
            discount: prev.discount + (next.totals?.discount || 0),
            final: prev.final + (next.totals?.line || 0)
        }
    }, {
        base: 0,
        tax: 0,
        discount: 0,
        final: 0
    });
}

const subject = new Subject<iCartContents>();
const defaultCartString = '{"items":[], "taxRate":0, "totals": {"base":0, "tax":0, "discount":0, "final":0}, "postalCode": "00000"}';

export const useCart = () => {
    const [contents, setContents] = useState<iCartContents>(
        JSON.parse(sessionStorage.getItem("cart") || defaultCartString)
    );

    const next = (cart: iCartContents) => {
        cart.totals = calcCartTotals(cart);
        setContents(cart);
        subject.next(cart);
    }

    const remove = (mod: iCartItem) => {
        const cart: iCartContents = JSON.parse(
            sessionStorage.getItem("cart") || defaultCartString
        );
        const inCart = cart.items.findIndex((i) => i.item.id === mod.item.id);
        if (inCart >= 0) {
            cart.items.splice(inCart, 1);
        }
        sessionStorage.setItem("cart", JSON.stringify(cart));
        next(cart);
    };

    const update = (mod: iCartItem) => {
        const cart: iCartContents = JSON.parse(
            sessionStorage.getItem("cart") || defaultCartString
        );
        mod.gift = !!mod.gift;
        const inCart = cart.items.findIndex((i) => i.item.id === mod.item.id);
        if (inCart >= 0) {
            if (mod.gift && cart.items[inCart].gift) {
                if (mod.quantity > 0) {
                    cart.items[inCart].quantity += mod.quantity;
                } else {
                    if (cart.items[inCart].quantity <= 0 - mod.quantity) {
                        cart.items[inCart].quantity = 0;
                    } else {
                        cart.items[inCart].quantity += mod.quantity;
                    }
                }
            } else {
                if (mod.quantity > 0) {
                    cart.items.push(mod);
                }
            }
        } else {
            if (mod.quantity > 0) {
                cart.items.push(mod);
            }
        }

        sessionStorage.setItem("cart", JSON.stringify(cart));
        next(cart);
    };

    const setTaxRate = (rate: number) => {
        const cart: iCartContents = JSON.parse(
            sessionStorage.getItem("cart") || defaultCartString
        );
 
        cart.taxRate = rate;
        sessionStorage.setItem("cart", JSON.stringify(cart));
        next(cart);
    }

    const clear = () => {
        const cart: iCartContents = JSON.parse(defaultCartString);
        sessionStorage.setItem("cart", JSON.stringify(cart));
        next(cart);
    };

    useEffect(() => {
        const sub: Subscription = subject.subscribe(setContents);
        return () => {
            if (typeof sub !== "undefined") sub.unsubscribe();
        };
    }, []);

    return { contents, remove, update, clear, setTaxRate };
};
