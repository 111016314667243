import { useEffect, useContext, useState } from "react";

import { useStyles } from "./CheckoutShippingStyle";
import {
    Box,
    Button,
    Grid,
    LinearProgress,
    Typography,
    useMediaQuery,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";

import { Navigate } from "react-router-dom";

import { ProfileContext } from "../../utilities/AuthProvider";

import { useTaxRate } from "../../remote/taxrate";
import { useShipping, iShipping, usStates } from "../../remote/shipping";

import { CartCard } from "../../components/Cards/CartCard/CartCard";
import { ContentLoading } from "../../components/ContentLoading/ContentLoading";
import { CustomNumericTextField } from "../../components/CustomNumericTextField/CustomNumericTextField";

import {
    FormContainer,
    SelectElement,
    TextFieldElement,
} from "react-hook-form-mui";
import { useForm } from "react-hook-form";

export const CheckoutShipping = () => {
    const classes = useStyles();

    const theme = useTheme();
    const isMdDown = useMediaQuery(theme.breakpoints.down("md"));

    const { isProfileLoading, profile } = useContext(ProfileContext);
    const shipping = useShipping();
    const [isSaving, setSaving] = useState(false);
    const [isSaveComplete, setSaveComplete] = useState(false);
    const formContext = useForm<iShipping>();
    const { reset, handleSubmit, watch } = formContext;

    const zip = watch("shippingAddress.postalCode");
    const [postalCode, setPostalCode] = useState("");
    const taxRate = useTaxRate(zip);

    const [prefilled, setPrefilled] = useState(false);

    useEffect(() => {
        if (zip && !taxRate.get.isLoading && zip !== postalCode) {
            setPostalCode(zip);
            taxRate.get.refresh();
        }
    }, [postalCode, taxRate.get, zip]);

    useEffect(() => {
        if (!shipping.get.isLoading && !prefilled) {
            reset(shipping.get.payload);
            setPrefilled(true);
        }
    }, [shipping, prefilled, reset]);

    useEffect(() => {
        if (isSaving && !shipping.set.isSaving) {
            setSaveComplete(true);
        }
    }, [isSaving, shipping]);

    if (isSaveComplete) return <Navigate to={"/checkout-payment"} />;
    if (!isProfileLoading && profile.membershipStatus === "active")
        return <Navigate to={"/"} />;
    if (isProfileLoading) return <ContentLoading />;

    const save = handleSubmit((data) => {
        setSaving(true);
        shipping.set.save(data);
    });

    return (
        <>
            <Box py={2.5}>
                <LinearProgress variant="determinate" value={50} />
            </Box>
            <Typography variant="h1">Checkout</Typography>
            <Typography variant="subtitle1" paragraph>
                1/2 Add your shipping details
            </Typography>
            <FormContainer
                FormProps={{ className: classes.form }}
                formContext={formContext}
                onSuccess={save}
            >
                <div className={classes.root}>
                    <Grid container spacing={3}>
                        <Grid item xs={6}>
                            <TextFieldElement
                                fullWidth
                                name="firstName"
                                label="First Name"
                                required
                                variant={isMdDown ? "filled" : "outlined"}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <TextFieldElement
                                fullWidth
                                name="lastName"
                                label="Last Name"
                                required
                                variant={isMdDown ? "filled" : "outlined"}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextFieldElement
                                fullWidth
                                name="shippingAddress.address"
                                label="Street Address"
                                required
                                variant={isMdDown ? "filled" : "outlined"}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <TextFieldElement
                                fullWidth
                                name="shippingAddress.city"
                                label="City"
                                required
                                variant={isMdDown ? "filled" : "outlined"}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <SelectElement
                                fullWidth
                                options={usStates}
                                name="shippingAddress.state"
                                label="State"
                                required
                                variant={isMdDown ? "filled" : "outlined"}
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <TextFieldElement
                                fullWidth
                                name="shippingAddress.postalCode"
                                label="Zip Code"
                                required
                                variant={isMdDown ? "filled" : "outlined"}
                                InputProps={{
                                    inputComponent:
                                        CustomNumericTextField as any,
                                    inputProps: {
                                        format: "#########",
                                    },
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <TextFieldElement
                                fullWidth
                                name="phoneNumber"
                                label="Phone"
                                required
                                variant={isMdDown ? "filled" : "outlined"}
                                InputProps={{
                                    inputComponent:
                                        CustomNumericTextField as any,
                                    inputProps: {
                                        phone: true,
                                    },
                                }}
                            />
                        </Grid>
                    </Grid>
                </div>
                <Box py={0.5}>
                    <Typography className={classes.buttonBar}>
                        <Button
                            disabled={isSaving || taxRate.get.isLoading}
                            type={"submit"}
                            variant={"contained"}
                            color={"secondary"}
                        >
                            {isSaving ? `Processing...` : `Continue to Payment`}
                        </Button>
                    </Typography>
                </Box>
            </FormContainer>
            <CartCard />
        </>
    );
};
