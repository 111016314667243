import { Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles((theme: Theme) => ({
    content: {
        flexGrow: 1,
    },
    root: {
        [theme.breakpoints.up("lg")]: {
            display: "flex",
        },
    },
}));
