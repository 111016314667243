import { useGetter } from '../utilities/getter';

export interface iTaxRate {
    state: string;
    tax: number;
}

export const defaultTaxRate: iTaxRate = {
    state: '',
    tax: 0
};

export const useTaxRate = (postalCode: string) => {
    const config = {
        type: 'taxrate',
        endpoint: `tax/`.concat(postalCode || '00000'),
        authenticate: false,
        list: false,
    };

    const getter = useGetter(defaultTaxRate, config);

    return {
        get: getter
    };
};
