import { Box, Button, Link, Typography } from "@mui/material";
import { NavLink as RouterLink } from "react-router-dom";

export const NotFound = () => {
    return (
        <>
            <Link component={RouterLink} to={`/`}></Link>
            <Box
                display="flex"
                justifyContent="center"
                style={{ textAlign: "center" }}
            >
                <Box py={2.5}>
                    <Typography variant={"h1"}>
                        Oops! We can't find that page
                    </Typography>
                    <Typography variant={"body1"}>
                        Despite our best efforts, you've found a page that
                        doesn't exist. Well done!
                    </Typography>
                    <Button
                        component={RouterLink}
                        to={`/`}
                        variant="contained"
                        color="secondary"
                    >
                        Take me to my dashboard
                    </Button>
                </Box>
            </Box>
        </>
    );
};
