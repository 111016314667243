import { useContext, useEffect, useState } from "react";
import { Navigate } from "react-router-dom";

import { Typography, Box, Button, useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { useStyles } from "./UpdatePhoneNumberStyle";

import { useForm } from "react-hook-form";
import { FormContainer, TextFieldElement } from "react-hook-form-mui";

import { iSendOTP, useOTP } from "../../remote/otp";
import { useShipping } from "../../remote/shipping";

import { ProfileContext } from "../../utilities/AuthProvider";

import { ContentLoading } from "../../components/ContentLoading/ContentLoading";
import { CustomNumericTextField } from "../../components/CustomNumericTextField/CustomNumericTextField";

export const UpdatePhoneNumber = () => {
    const classes = useStyles();

    const theme = useTheme();
    const isMdDown = useMediaQuery(theme.breakpoints.down("md"));

    const { isProfileLoading, profileRefresh } = useContext(ProfileContext);
    const shipping = useShipping();
    const formContext = useForm<iSendOTP>();
    const { reset, handleSubmit } = formContext;
    const [isSending, setSending] = useState(false);
    const [isSendingComplete, setSendingComplete] = useState(false);
    const [phoneNumber, setPhoneNumber] = useState("");
    const { send } = useOTP();
    const [prefilled, setPrefilled] = useState(false);

    useEffect(() => {
        if (!shipping.get.isLoading && !prefilled) {
            reset(shipping.get.payload);
            setPrefilled(true);
        }
    }, [shipping, prefilled, reset]);

    useEffect(() => {
        if (isSending && !send.isSaving) {
            profileRefresh();
            setSendingComplete(true);
        }
    }, [isSending, profileRefresh, send.isSaving]);

    const save = handleSubmit((data) => {
        setSending(true);
        setPhoneNumber(data.phoneNumber);
        send.save({ phoneNumber: data.phoneNumber });
    });

    if (isSendingComplete) {
        const encoded = encodeURIComponent(phoneNumber);
        return <Navigate to={`/verify-otp?phone=${encoded}`} />;
    }

    if (isProfileLoading || shipping.get.isLoading) return <ContentLoading />;

    return (
        <>
            <Typography variant={"h2"}>Update Phone Number</Typography>
            <Typography paragraph>
                For the security of your account, we'll send you a one-time
                passcode to verify your new phone number.
            </Typography>
            <FormContainer formContext={formContext} onSuccess={save}>
                <Box className={classes.fieldBox}>
                    <TextFieldElement
                        fullWidth
                        name="phoneNumber"
                        label="Phone"
                        required
                        variant={isMdDown ? "filled" : "outlined"}
                        InputProps={{
                            inputComponent: CustomNumericTextField as any,
                            inputProps: {
                                phone: true,
                            },
                        }}
                    />
                </Box>
                <Typography className={classes.buttonBar}>
                    <Button
                        disabled={isSending}
                        type={"submit"}
                        variant={"contained"}
                        color={"secondary"}
                    >
                        {isSending
                            ? `Processing...`
                            : `Send my one-time passcode`}
                    </Button>
                </Typography>
            </FormContainer>
        </>
    );
};
