import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles(() => ({
    cardTitle: {
        textTransform: "uppercase",
        fontSize: 16,
        letterSpacing: ".065rem",
        fontWeight: 600,
    },
    table: {
        "& .MuiTableCell-root": {
            borderBottomWidth: 0,
            verticalAlign: "top",
        },
        "& .MuiTableCell-root:last-child": {
            textAlign: "right",
        },
        "& .MuiTableRow-root:last-child .MuiTableCell-root": {
            fontWeight: "700",
            borderTop: "2px solid rgba(224, 224, 224, 1)",
        },
    },
}));
