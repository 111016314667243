import { useEffect, useState } from "react";
import { Subscription, from } from "rxjs";

import { Auth } from "aws-amplify";

export const useToken = () => {
    const [token, setToken] = useState<string | undefined>();

    useEffect(() => {
        const getToken = async () => {
            const user = await Auth.currentSession();
            const token = user.getIdToken().getJwtToken();

            return token;
        };

        const sub: Subscription = from(getToken()).subscribe({
            next: (result: string) => {
                setToken(result);
            },
        });

        return () => {
            if (typeof sub !== "undefined") sub.unsubscribe();
        };
    }, []);

    return token;
};
