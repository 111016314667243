import { useSetter } from "../utilities/setter";

export interface iSendOTP {
    phoneNumber: string;
    message?: string;
}

export const defaultSend: iSendOTP = {
    phoneNumber: "",
};

export interface iVerifyOTP {
    otp: string;
}

export const defaultVerify: iVerifyOTP = {
    otp: "",
};

export const useOTP = () => {
    const sendConfig = {
        type: "otp",
        endpoint: `sms/send`,
        authenticate: true,
        list: false,
    };

    const verifyConfig = {
        type: "otp",
        endpoint: `sms/verify`,
        authenticate: true,
        list: false,
    };

    const verify = useSetter(defaultVerify, verifyConfig);
    const send = useSetter(defaultSend, sendConfig);

    return {
        verify: verify,
        send: send,
    };
};
