export interface iSteps {
    step: "review" | "genetic" | "blood" | "future" | "risks";
    title: string;
    value: number;
    pageTitle?: string;
    content: string;
}

export const steps: iSteps[] = [
    {
        step: "review",
        title: "HIPAA Authorization",
        pageTitle: "Review Informed Consent",
        value: 20,
        content:
            "To the extent that t48 uses your information.",
    },
    {
        step: "genetic",
        title: "Test",
        value: 40,
        content:
            "t48 performs amazing feats to satisfy you, the customer",
    },
    {
        step: "blood",
        title: "Blood Test",
        value: 60,
        content:
            "t48 performs amazing feats to satisfy you, the customer",
    },
    {
        step: "future",
        title: "Future Testing",
        value: 80,
        content:
            "t48 performs amazing feats to satisfy you, the customer",
    },
    {
        step: "risks",
        title: "Risks & Limitations",
        value: 100,
        content:
            "t48 performs amazing feats to satisfy you, the customer",
    },
];
