import { Theme, Box } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { BrowserRouter as Router } from "react-router-dom";
import { Navigation } from "./chrome/Navigation/Navigation";
import { Content } from "./components/Content/Content";

const useStyles = makeStyles((theme: Theme) => ({
    contentBox: {
        maxWidth: `1140px`,
        padding: theme.spacing(3),
        paddingBottom: "80px",
        marginLeft: "auto",
        marginRight: "auto",
    },
}));

const App = () => {
    const classes = useStyles();

    return (
        <Router>
            <Navigation>
                <Box className={classes.contentBox}>
                    <Content />
                </Box>
            </Navigation>
        </Router>
    );
};

export default App;
