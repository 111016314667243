import { useEffect, useState } from "react";

import { Box, Typography, useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/material/styles";

import { UseFormWatch, UseFormReset } from "react-hook-form";
import { CheckboxButtonGroup, TextFieldElement } from "react-hook-form-mui";

import { ancestryList } from "../../remote/ancestry";
import { iFormDetails } from "../../remote/details";
import { useStyles } from "./AncestryFromStyle";

export const AncestryForm = ({
    watch,
    reset,
}: {
    reset: UseFormReset<iFormDetails>;
    watch: UseFormWatch<iFormDetails>;
}) => {
    const classes = useStyles();

    const theme = useTheme();
    const isMdDown = useMediaQuery(theme.breakpoints.down("md"));

    const [hasAncestry, setAncestry] = useState(false);
    const [hasOther, setOther] = useState(false);
    const [hasOtherValue, setOtherValue] = useState(false);
    const [hasPreferred, setPreferred] = useState(false);
    const ancestry = watch("ancestry");
    const other = watch("other");
    const otherValue = watch("otherValue");
    const preferred = watch("preferred");

    useEffect(() => {
        if (ancestry && !!ancestry.length) {
            setAncestry(true);
        }

        if (other && !!other.length) {
            setOther(true);
        }

        if (preferred && !!preferred.length) {
            setPreferred(true);
        }
    }, [ancestry, other, preferred]);

    useEffect(() => {
        if (otherValue) {
            setOther(true);
            setOtherValue(true);
        } else {
            setOtherValue(false);
        }
    }, [otherValue]);

    const handleCheckboxChange = (
        t:
            | "ancestry"
            | "other"
            | "preferred"
            | "unAncestry"
            | "unPreferred"
            | "unOther"
    ) => {
        const n = watch(t);

        if (!!n.length) {
            switch (t) {
                case "ancestry":
                case "unAncestry":
                    setAncestry(true);
                    break;
                case "other":
                case "unOther":
                    setOther(true);
                    reset({
                        ...watch(),
                        otherValue: "",
                    });
                    break;
                case "preferred":
                case "unPreferred":
                    setPreferred(true);
                    setAncestry(false);
                    setOther(false);
                    reset({
                        ...watch(),
                        ancestry: [],
                        unAncestry: [],
                        other: [],
                        unOther: [],
                        otherValue: "",
                    });
            }
        } else {
            switch (t) {
                case "ancestry":
                case "unAncestry":
                    setAncestry(false);
                    break;
                case "other":
                case "unOther":
                    reset({
                        ...watch(),
                        other: [],
                        unOther: [],
                        otherValue: "",
                    });
                    setOther(false);
                    break;
                case "preferred":
                case "unPreferred":
                    setPreferred(false);
            }
        }
    };

    return (
        <Box className={classes.fieldBox}>
            <Box display="inlineBlock">
                {(hasAncestry && hasOther) ||
                (hasAncestry && hasOther && !hasPreferred) ||
                (!hasAncestry &&
                    !(hasOther && hasOtherValue) &&
                    !hasPreferred) ||
                (hasAncestry && !hasOther && !hasPreferred) ? (
                    <CheckboxButtonGroup
                        parseError={() => ``}
                        options={ancestryList.map((t) => {
                            return { id: t, label: t };
                        })}
                        name="ancestry"
                        onChange={() => handleCheckboxChange("ancestry")}
                        required
                    />
                ) : (
                    <CheckboxButtonGroup
                        disabled={hasPreferred}
                        options={ancestryList.map((t) => {
                            return { id: t, label: t };
                        })}
                        name="unAncestry"
                        onChange={() => handleCheckboxChange("unAncestry")}
                    />
                )}
            </Box>
            <Box display="inlineBlock">
                {hasAncestry || hasPreferred ? (
                    <CheckboxButtonGroup
                        disabled={hasPreferred}
                        options={[{ id: "1", label: "Other" }]}
                        name="unOther"
                        onChange={() => handleCheckboxChange("unOther")}
                    />
                ) : (
                    <CheckboxButtonGroup
                        parseError={() => ``}
                        options={[{ id: "1", label: "Other" }]}
                        name="other"
                        onChange={() => handleCheckboxChange("other")}
                        required
                    />
                )}
            </Box>
            {hasOther ? (
                <>
                    <Typography variant="subtitle2" color="textSecondary">
                        You selected Other. Please describe your ancestry.
                    </Typography>
                    <TextFieldElement
                        required
                        name="otherValue"
                        label="Other"
                        variant={isMdDown ? "filled" : "outlined"}
                    />
                </>
            ) : (
                ``
            )}
            <Box display="inlineBlock">
                {hasAncestry || hasOther ? (
                    <CheckboxButtonGroup
                        options={[{ id: "1", label: "I prefer not to say" }]}
                        name="unPreferred"
                        onChange={() => handleCheckboxChange("unPreferred")}
                    />
                ) : (
                    <CheckboxButtonGroup
                        options={[{ id: "1", label: "I prefer not to say" }]}
                        name="preferred"
                        onChange={() => handleCheckboxChange("preferred")}
                        required
                    />
                )}
            </Box>
        </Box>
    );
};
