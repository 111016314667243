import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles(() => ({
    buttonBar: {
        marginTop: "1rem",
    },
    form: {
        marginBottom: "2rem",
        " .form": {
            width: "100%",
        },
    },
}));
