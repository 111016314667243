import { useSetter } from "../utilities/setter";

export const useConsent = () => {
    const config = {
        type: "consent",
        endpoint: `consent`,
        authenticate: true,
        list: false,
    };

    const set = useSetter({}, config);

    return {
        set: set,
    };
};
