import { Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles((theme: Theme) => ({
    imageContainer: {
        justifyContent: "center",
        display: "flex",
    },
    profileImage: {
        width: theme.spacing(14),
        height: theme.spacing(14),
    },
}));
