import { NavLink as RouterLink } from "react-router-dom";
import { useStyles } from "./CustomDrawerStyles";
import { iMenuList, MenuList } from "../menuList";

import { useTheme } from "@mui/material/styles";
import {
    Button,
    Drawer,
    List,
    ListItem,
    Toolbar,
    useMediaQuery,
} from "@mui/material";
interface iProps {
    window: (() => Window) | undefined;
    mobileOpen: boolean;
    handleUserLogout: () => void;
    setMobileOpen: (value: boolean) => void;
}

export const CustomDrawer = ({
    window,
    mobileOpen,
    handleUserLogout,
    setMobileOpen,
}: iProps) => {
    const classes = useStyles();

    const theme = useTheme();
    const isLarger = useMediaQuery(theme.breakpoints.up("lg"));

    const handleDrawerToggle = () => {
        setMobileOpen(!mobileOpen);
    };

    const container =
        window !== undefined ? () => window().document.body : undefined;

    return (
        <Drawer
            className={classes.drawer}
            container={container}
            variant={"temporary"}
            anchor={"right"}
            open={mobileOpen}
            onClose={handleDrawerToggle}
            classes={{
                paper: classes.drawerPaper,
            }}
            ModalProps={{
                keepMounted: true,
            }}
        >
            {isLarger ? <Toolbar /> : ``}
            <div className={classes.drawerContainer}>
                <List>
                    {MenuList.map((nav: iMenuList, key: number) => (
                        <ListItem
                            dense
                            divider={nav.divider}
                            className={classes.drawerListItem}
                            end
                            component={RouterLink}
                            to={nav.link}
                            onClick={() => setMobileOpen(false)}
                            key={key}
                        >
                            <Button className={classes.drawerButton} fullWidth>
                                {nav.label}
                            </Button>
                        </ListItem>
                    ))}
                    <ListItem
                        dense
                        className={classes.drawerListItem}
                        onClick={() => {
                            setMobileOpen(false);
                            handleUserLogout();
                        }}
                    >
                        <Button className={classes.drawerButton} fullWidth>
                            Logout
                        </Button>
                    </ListItem>
                </List>
            </div>
        </Drawer>
    );
};
