import { Route, Routes } from "react-router-dom";

import { Dashboard } from "../../../pages/Dashboard/Dashboard";
import { SampleSendVerification } from "../../../experiments/SampleSendVerification";
import { CompleteDetails } from "../../../pages/CompleteDetails/CompleteDetails";
import { EmailVerification } from "../../../pages/EmailVerification/EmailVerification";
import { InformedConsent } from "../../../pages/InformedConsent/InformedConsent";
import { Membership } from "../../../pages/Membership/Membership";
import { NotFound } from "../../../pages/NotFound/NotFound";
import { OrderDetails } from "../../../pages/OrderDetails/OrderDetails";
import { Profile } from "../../../pages/Profile/Profile";
import { UpdateAddress } from "../../../pages/UpdateAddress/UpdateAddress";
import { UpdateAncestry } from "../../../pages/UpdateAncestry/UpdateAncestry";
import { UpdatePhoneNumber } from "../../../pages/UpdatePhoneNumber/UpdatePhoneNumber";
import { VerifyOneTimePasscode } from "../../../pages/VerifyOneTimePasscode/VerifyOneTimePasscode";

import { Amplify } from "aws-amplify";
import { withAuthenticator } from "@aws-amplify/ui-react";
import "@aws-amplify/ui-react/styles.css";

import awsExports from "../../../aws-exports";
Amplify.configure(awsExports);

const ProtectedApp = () => (
    <Routes>
        {/* private routes */}
        <Route path="/login" element={<Membership />} />
        <Route path={"/order/:id"} element={<OrderDetails />} />
        <Route path={"/profile"} element={<Profile />} />
        <Route path={"/complete-details"} element={<CompleteDetails />} />
        <Route path={"/informed-consent"} element={<InformedConsent />} />
        <Route path={"/update-ancestry"} element={<UpdateAncestry />} />
        <Route
            path={"/send-email-verification"}
            element={<SampleSendVerification />}
        />
        <Route path={"/email-verification"} element={<EmailVerification />} />
        <Route path={"/verify-otp"} element={<VerifyOneTimePasscode />} />
        <Route path={"/update-phone-number"} element={<UpdatePhoneNumber />} />
        <Route path={"/update-address"} element={<UpdateAddress />} />

        <Route path="/" element={<Dashboard />} />
        <Route path="*" element={<NotFound />} />
    </Routes>
);

export default withAuthenticator(ProtectedApp);
