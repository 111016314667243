import { Box, Typography, Grid } from "@mui/material";
import { SupportCard } from "../Cards/SupportCard/SupportCard";

export const Support = () => {
    return (
        <Box mt={4}>
            <Typography variant="subtitle1">Get Support</Typography>
            <Box mt={1}>
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={6}>
                        <SupportCard
                            icon="phone"
                            label="Call us at"
                            contact="(800) 772-0593"
                            url="tel:800-772-0593"
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <SupportCard
                            icon="email"
                            label="Email us at"
                            contact="support@t48.com"
                            url="mailto:support@t48.com"
                        />
                    </Grid>
                </Grid>
            </Box>
        </Box>
    );
};
