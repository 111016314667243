import { Fontface } from "@mui/material/styles/createMixins";
import CalibreLight from "../webfonts/calibre-light.woff2";
import CalibreMedium from "../webfonts/calibre-medium.woff2";
import MabryRegularPro from "../webfonts/mabry-regular-pro.woff2";
import MabryMediumPro from "../webfonts/mabry-medium-pro.woff2";

export const calibreLight: Fontface = {
    fontFamily: "Calibre Light",
    fontStyle: "normal",
    fontDisplay: "swap",
    fontWeight: 400,
    src: `
        url(${CalibreLight}) format('woff2')
    `,
};

export const calibreMedium: Fontface = {
    fontFamily: "Calibre Medium",
    fontStyle: "normal",
    fontDisplay: "swap",
    fontWeight: 400,
    src: `
        url(${CalibreMedium}) format('woff2')
    `,
};

export const mabryRegularPro: Fontface = {
    fontFamily: "Mabry Regular Pro",
    fontStyle: "normal",
    fontDisplay: "swap",
    fontWeight: 400,
    src: `
        url(${MabryRegularPro}) format('woff2')
    `,
};

export const mabryMediumPro: Fontface = {
    fontFamily: "Mabry Medium Pro",
    fontStyle: "normal",
    fontDisplay: "swap",
    fontWeight: 400,
    src: `
        url(${MabryMediumPro}) format('woff2')
    `,
};
