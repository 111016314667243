import { Navigate, Route, Routes } from "react-router-dom";

import { ThankYou } from "../../pages/ThankYou/ThankYou";
import { CheckoutShipping } from "../../pages/CheckoutShipping/CheckoutShipping";
import { CheckoutPayment } from "../../pages/CheckoutPayment/CheckoutPayment";
import { Enroll } from "../Enroll/Enroll";
import PrivateContent from "./PrivateContent/PrivateContent";

const Login = () => <Navigate to={"/"} />;

const PostLogin = () => <Navigate to={"/"} />;

export const Content = () => {
    return (
        <>
            <Routes>
                {/* public routes */}
                <Route path={"/enroll"} element={<Enroll />} />
                <Route path={"/thank-you"} element={<ThankYou />} />
                <Route path={"/postlogin"} element={<PostLogin />} />
                <Route path={"/login"} element={<Login />} />

                {/* private routes */}
                <Route path="*" element={<PrivateContent />} />

                {/* checkout routes - public because we will confirm auth and membership
                and redirect manually, plus don't want chrome */}
                <Route
                    path={"/checkout-shipping"}
                    element={<CheckoutShipping />}
                />
                <Route
                    path={"/checkout-payment"}
                    element={<CheckoutPayment />}
                />
            </Routes>
        </>
    );
};
