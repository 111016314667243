import { useContext } from "react";
import { NavLink as RouterLink } from "react-router-dom";
import HomeIcon from "@mui/icons-material/Home";
import HotTubIcon from "@mui/icons-material/HotTub";
import PersonIcon from "@mui/icons-material/Person";
import { ProfileContext } from "../../utilities/AuthProvider";

import { useTheme } from "@mui/material/styles";
import {
    BottomNavigation,
    BottomNavigationAction,
    useMediaQuery,
} from "@mui/material";
import { useStyles } from "./BottomNavStyle";

export const BottomNav = () => {
    const { isProfileLoading, profile } = useContext(ProfileContext);

    const theme = useTheme();
    const isLarger = useMediaQuery(theme.breakpoints.up("sm"));
    const classes = useStyles();

    return (
        <>
            {isLarger ||
            isProfileLoading ||
            profile.membershipStatus !== "active" ? (
                ``
            ) : (
                <BottomNavigation showLabels className={classes.bottomNav}>
                    <BottomNavigationAction
                        component={RouterLink}
                        to={"/"}
                        label="Dashboard"
                        icon={<HomeIcon />}
                        className={classes.bottomNavAction}
                    />
                    <BottomNavigationAction
                        component={RouterLink}
                        to={"/membership"}
                        label="Membership"
                        icon={<HotTubIcon />}
                        className={classes.bottomNavAction}
                    />
                    <BottomNavigationAction
                        component={RouterLink}
                        to={"/profile"}
                        label="Profile"
                        icon={<PersonIcon />}
                        className={classes.bottomNavAction}
                    />
                </BottomNavigation>
            )}
        </>
    );
};
