import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles(() => ({
    form: {
        marginBottom: "2rem",
        " .form": {
            width: "100%",
        },
    },
    buttonBar: {
        marginTop: "1rem",
    },
    fieldBox: {
        marginBottom: "1rem",
    },
    twoColumn: {
        display: "flex",
        justifyContent: "space-between",
        "& .MuiTextField-root": {
            width: "calc(50% - 0.5rem)",
        },
    },
}));
