import { createTheme } from "@mui/material/styles";

import {
    calibreLight,
    calibreMedium,
    mabryMediumPro,
    mabryRegularPro,
} from "./FontFaces";

// Create the base theme so we can access things like breakpoints
// when defining our actual theme
const baseTheme = createTheme();

// Now extend baseTheme, because <see above>
export const mainTheme = createTheme(baseTheme, {
    palette: {
        background: {
            default: "#fff",
        },
        primary: {
            main: "#21313D",
        },
        secondary: {
            main: "#E3245A",
        },
        contrastThreshold: 3,
        tonalOffset: 0.2,
    },
    typography: {
        fontFamily: [
            "Calibre Light",
            "Calibre Medium",
            "Mabry Regular Pro",
            "Mabry Medium Pro",
        ].join(","),
        body1: {
            fontFamily: "Calibre Light",
            fontSize: "18px",
        },
        body2: {
            fontFamily: "Calibre Light",
            fontSize: "18px",
        },
        h1: {
            fontFamily: "Mabry Medium Pro",
            fontSize: "32px",
            marginBottom: "1.2rem",
            [baseTheme.breakpoints.up("md")]: {
                fontSize: "54px",
                marginBottom: "1rem",
            },
        },
        h2: {
            fontFamily: "Mabry Medium Pro",
            fontSize: "28px",
            marginBottom: "1.2rem",
            [baseTheme.breakpoints.up("md")]: {
                fontSize: "36px",
                marginBottom: "1rem",
            },
        },
        h3: {
            fontFamily: "Mabry Medium Pro",
            fontSize: "22px",
            marginBottom: "1.2rem",
            [baseTheme.breakpoints.up("md")]: {
                fontSize: "24px",
                marginBottom: "1rem",
            },
        },
        h4: {
            fontFamily: "Calibre Medium",
            fontSize: "18x",
            marginBottom: "1rem",
            [baseTheme.breakpoints.up("md")]: {
                fontSize: "22px",
                marginBottom: "1rem",
            },
        },
        h5: {
            fontFamily: "Calibre Medium",
            fontSize: "18px",
            marginBottom: "1rem",
            [baseTheme.breakpoints.up("md")]: {
                fontSize: "20px",
                marginBottom: "1rem",
            },
        },
        subtitle1: {
            fontFamily: "Mabry Regular Pro",
            fontSize: "18px",
            marginBottom: "1rem",
            [baseTheme.breakpoints.up("md")]: {
                fontSize: "22px",
                marginBottom: "1rem",
            },
        },
        subtitle2: {
            fontFamily: "Mabry Regular Pro",
            fontSize: "16px",
            marginBottom: "1rem",
            [baseTheme.breakpoints.up("md")]: {
                fontSize: "20px",
                marginBottom: "1rem",
            },
        },
        overline: {
            display: "block",
            fontFamily: "Calibre Light",
            fontSize: "14px",
            lineHeight: 1.4,
            textTransform: "uppercase",
            letterSpacing: ".065rem",
        },
    },
    components: {
        MuiCssBaseline: {
            styleOverrides: {
                "@font-face": calibreLight,
                fallbacks: [
                    {
                        "@font-face": calibreMedium,
                    },
                    {
                        "@font-face": mabryMediumPro,
                    },
                    {
                        "@font-face": mabryRegularPro,
                    },
                ],
                a: {
                    cursor: "pointer",
                },
            },
        },
        MuiAlert: {
            styleOverrides: {
                root: {
                    fontSize: "18px",
                    padding: "10px",
                    lineHeight: 1,
                    [baseTheme.breakpoints.up("md")]: {
                        fontSize: "22px",
                        padding: "20px",
                    },
                },
            },
        },
        MuiButton: {
            styleOverrides: {
                root: {
                    borderRadius: 50,
                    fontFamily: "Calibre Medium",
                    textTransform: "uppercase",
                    fontSize: 12,
                    letterSpacing: ".065rem",
                    padding: "14px 35px",
                    minWidth: 180,
                    marginTop: "1rem",
                    marginBottom: "1rem",

                    // Since conditional defaultProps is not yet supported
                    // This should be good for now
                    [baseTheme.breakpoints.down("sm")]: {
                        width: "100%",
                    },
                },
            },
            defaultProps: {
                disableElevation: true,

                // NOTE: This is not working for MUIv5 anymore
                // We can use baseTheme.breakpoints here because it
                // was already created
                // [baseTheme.breakpoints.down("sm")]: {
                //     fullWidth: true,
                // },
            },
        },
        MuiOutlinedInput: {
            styleOverrides: {
                root: {
                    borderRadius: 0,
                },
            },
        },
        MuiInputLabel: {
            styleOverrides: {
                root: {
                    borderWidth: 1,
                },
            },
        },
        MuiTextField: {
            styleOverrides: {
                root: {
                    // Since conditional defaultProps is not yet supported
                    // This should be good for now
                    [baseTheme.breakpoints.down("md")]: {
                        width: "100%",
                    },
                },
            },
            defaultProps: {
                // NOTE: This is not working for MUIv5 anymore
                // [baseTheme.breakpoints.down("md")]: {
                //     variant: "filled",
                //     fullWidth: true,
                // },
            },
        },
        MuiLink: {
            defaultProps: {
                underline: "none",
            },
        },
    },
});
