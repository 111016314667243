import { useGetter } from "../utilities/getter";

interface iOwner {
    id: string;
    firstName: string;
    lastName: string;
    email: string;
    phone?: string;
}

interface iPayment {
    id: string;
    amount: number;
    created: string;
}
export interface iOrder {
    id: string;
    items: any[];
    ownerId: string;
    owner: iOwner;
    billingAddress: string;
    shippingAddress: string;
    purchaseDate: string;
    payment: iPayment;
}

const defaultOrder: iOrder = {
    id: "",
    items: [],
    ownerId: "",
    owner: {
        id: "",
        firstName: "",
        lastName: "",
        email: "",
    },
    billingAddress: "",
    shippingAddress: "",
    purchaseDate: "",
    payment: {
        id: "",
        amount: 0,
        created: "",
    },
};

export const useOrder = (id: string) => {
    const config = {
        type: "orders",
        endpoint: `orders/${id}`,
        authenticate: true,
        list: false,
    };

    const getter = useGetter(defaultOrder, config);

    return {
        get: getter,
    };
};
