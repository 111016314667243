import { NavLink as RouterLink } from "react-router-dom";

import { Box, Typography, Chip, Button, Divider } from "@mui/material";

import EditIcon from "@mui/icons-material/Edit";

import clsx from "clsx";
import { DateTime } from "luxon";
import { useStyles } from "./ProfileCardStyle";

interface iShippingAddress {
    address: string;
    city: string;
    postalCode: string;
    state: string;
}

interface iProps {
    title: string;
    value: string | iShippingAddress;
    isDate?: boolean;
    link?: string;
    editable?: boolean;
    divider?: boolean;
    hasChip?: boolean;
    chipTitle?: string;
    chipType?: "success" | "warning";
}

export const ProfileCard = (props: iProps) => {
    const classes = useStyles();
    const {
        title,
        value,
        link,
        editable,
        divider,
        hasChip,
        chipTitle,
        chipType,
        isDate,
    } = props;

    let filteredValue = <></>;

    if (isDate) {
        filteredValue = (
            <>
                {DateTime.fromISO(value as string).toLocaleString(
                    DateTime.DATE_FULL
                )}
            </>
        );
    } else {
        if (typeof value === "string") {
            filteredValue = <>{value}</>;
        } else if (!Array.isArray(value) && typeof value === "object") {
            filteredValue = (
                <>
                    {value.address} <br />
                    {value.city} {value.state} {value.postalCode}
                </>
            );
        } else if (Array.isArray(value) && !!value.length) {
            filteredValue = <>{value.join(", ")}</>;
        } else {
            filteredValue = <>Not provided</>;
        }
    }

    return (
        <>
            <Box my={1}>
                <Box className={classes.header}>
                    <Box className={classes.flexGrow}>
                        <Typography
                            className={classes.title}
                            variant="subtitle2"
                        >
                            {title}
                        </Typography>
                        {hasChip ? (
                            <Chip
                                className={clsx(classes.chip, {
                                    [classes.isChipSuccess]:
                                        chipType === "success",
                                    [classes.isChipWarning]:
                                        chipType === "warning",
                                })}
                                size="small"
                                label={chipTitle}
                            />
                        ) : (
                            ``
                        )}
                    </Box>
                    {editable ? (
                        <Button
                            className={classes.button}
                            size="small"
                            color="primary"
                            endIcon={<EditIcon />}
                            component={RouterLink}
                            to={link ? link : `/`}
                        >
                            <Typography variant="subtitle2">Edit</Typography>
                        </Button>
                    ) : (
                        ``
                    )}
                </Box>
                <Typography variant="h6">
                    {value ? filteredValue : `Not provided`}
                </Typography>
            </Box>
            {divider ? <Divider /> : ``}
        </>
    );
};
