import { Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles((theme: Theme) => ({
    button: {
        textTransform: "none",
        minWidth: 0,
    },
    chip: {
        marginRight: theme.spacing(1),
        color: "#FFFFFF",
        padding: theme.spacing(0, 1),
    },
    grid: {
        textAlign: "center",
    },
    isChipSuccess: {
        backgroundColor: theme.palette.success.main,
    },
    isChipPending: {
        backgroundColor: theme.palette.grey[600],
    },
    list: {
        border: `1px solid ${theme.palette.divider}`,
        borderRadius: theme.shape.borderRadius,
    },
    negative: {
        color: theme.palette.error.main,
    },
    positive: {
        color: theme.palette.success.main,
    },
    underline: {
        textDecoration: "underline",
    },
}));
