import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles(() => ({
    buttonBar: {
        marginTop: "1rem",
    },
    fieldBox: {
        marginBottom: "1rem",
    },
}));
