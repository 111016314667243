import { useState } from "react";
import { NavLink as RouterLink } from "react-router-dom";

import CheckIcon from "@material-ui/icons/Check";
import DirectionsRunIcon from "@material-ui/icons/DirectionsRun";

import clsx from "clsx";

import { Avatar, Card, CardHeader, Link } from "@mui/material";

import { useStyles } from "./ReadinessCardStyle";

interface iProps {
    completed?: boolean;
    title: string;
    subheader?: {
        completed?: string;
        incomplete?: string;
    };
    link?: {
        label: string;
        path: string;
    };
    func?: {
        label: string;
        onClick: () => void;
    };
}

export const ReadinessCard = (props: iProps) => {
    const classes = useStyles();
    const [clicked, setClicked] = useState(false);

    const { completed, title, subheader, link, func } = props;

    return (
        <>
            <Card
                className={clsx(
                    classes.card,
                    completed ? classes.completed : ``
                )}
                elevation={0}
            >
                <CardHeader
                    avatar={
                        <Avatar>
                            {completed ? <CheckIcon /> : <DirectionsRunIcon />}
                        </Avatar>
                    }
                    title={title}
                    subheader={
                        <>
                            {completed
                                ? subheader?.completed || ``
                                : subheader?.incomplete || ``}
                            &nbsp;
                            {!completed && link ? (
                                <RouterLink to={link!.path}>
                                    {link!.label}
                                </RouterLink>
                            ) : (
                                ``
                            )}
                            {!completed && !clicked && func ? (
                                <Link
                                    className={classes.link}
                                    color="inherit"
                                    onClick={() => {
                                        setClicked(true);
                                        func.onClick();
                                    }}
                                >
                                    {func!.label}
                                </Link>
                            ) : (
                                ``
                            )}
                            {!completed && clicked && func ? `Sent` : ``}
                        </>
                    }
                ></CardHeader>
            </Card>
        </>
    );
};
