import { Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles((theme: Theme) => ({
    button: {
        color: "#21313D",
        textTransform: "none",
        padding: "10px 15px",
        fontFamily: "Calibre Light",
    },
    error: {
        color: theme.palette.error.main,
    },
}));
