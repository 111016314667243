const mapType = (target: any, source: any) => {
    const type = typeof target;
    if (source === null) return target;

    switch (type) {
        case 'string':
            return String.bind(null, source)();

        case 'number':
            return Number.bind(null, source)();

        case 'boolean':
            if (source === 'true') return true;
            if (source === 'false') return false;
            return Boolean.bind(null, source)();

        default:
            return source;
    }
};

const extractData = (data: any, defaults: any, sources: any) => {
    // Start with defaults
    // Assumes defaults doesn't include any functions, etc
    // Weed out any undefineds and replace them with null
    let output = JSON.parse(
        JSON.stringify(defaults, (k, v) => (v === undefined ? null : v))
    );

    // Only works for extracting data from a series of top level attributes
    // But, this is perfect for getting values that could be in body, query, or path
    // parameters of an event
    // Attempts to do data type conversion to match defaults when finding basic literals
    // Recursively examines properties that are objects in the defaults to try to
    // catch deep defaults
    sources.forEach((s: any) => {
        // sources can be a string or an object with name and boolean parse string
        // sources are set to be the name and parse to be false
        const name = typeof s === 'string' ? s : s.name;
        const parse =
            typeof s === 'string'
                ? false
                : s.hasOwnProperty('parse') && typeof s.parse === 'boolean'
                ? s.parse
                : false;

        // Does the source data even have this property?
        const hasSource = !(
            typeof data !== 'object' ||
            !data.hasOwnProperty(name) ||
            !data[name]
        );

        if (hasSource) {
            // parse it from JSON if we said to do that
            const source = parse ? JSON.parse(data[name]) : data[name];

            // hunt for the properties in source, overwrite defaults if present
            for (let prop in output) {
                if (typeof source[prop] !== 'undefined') {
                    if (
                        typeof defaults[prop] === 'object' &&
                        !Array.isArray(defaults[prop])
                    ) {
                        // for child objects, recursively extract data here, too
                        output[prop] = extractData(
                            { [prop]: source[prop] },
                            defaults[prop],
                            [prop]
                        );
                    } else {
                        // for basic literal types, attempt to retain datatype
                        output[prop] = mapType(output[prop], source[prop]);
                    }
                }
            }
        }
    });

    return output;
};


// Forces, at depth, for an object to conform to the structure of a default
// Properties of source not in defaults are dropped Properties of source with
// any value except undefined are retained Properties of defaults not in source
// are included with default values Properties of type object or array are
// copied, dereferenced Arrays of objects are copied, dereferenced shallowly ^
// So do not use this if you might have arrays of objects containing nested
// objects   If you do, changes to the resulting object could affect the source
const normalizeData = (source: any, defaults: any) => {
    const output: any = {};
    for (let prop in defaults) {
      if (
        source.hasOwnProperty(prop) &&
        typeof source[prop] !== undefined &&
        source[prop] !== null
      ) {
        if (typeof defaults[prop] === 'object') {
          if (Array.isArray(defaults[prop])) {
            output[prop] = Array.from(source[prop], i => {
              return typeof i === 'object' ? Object.assign({}, i) : i;
            });
          } else {
            output[prop] = Object.assign(
              {},
              normalizeData(source[prop], defaults[prop])
            );
          }
        } else {
          output[prop] = source[prop];
        }
      } else {
        output[prop] = defaults[prop];
      }
    }
    return output;
  };
  
export const utility = {
    extractData,
    normalizeData,
    mapType,
};
