import { useAuth0 } from "@auth0/auth0-react";

export const Enroll = () => {
    const redirectUri = window.location.origin;
    const { loginWithRedirect } = useAuth0();

    loginWithRedirect({
        redirectUri: redirectUri,
        screen_hint: "signup",
    });

    return <></>;
};
