import React from "react";
import NumberFormat, { NumberFormatProps } from "react-number-format";
import { defaultUSPhoneFormat } from "../../utilities/phone";

interface CustomProps {
    onChange: (event: { target: { name: string; value: string } }) => void;
    name: string;
    format?: string;
    phone?: boolean;
}

export const CustomNumericTextField = React.forwardRef<
    NumberFormatProps,
    CustomProps
>(function NumberFormatCustom(props, ref) {
    const { format, phone, onChange, ...other } = props;

    return (
        <NumberFormat
            {...other}
            getInputRef={ref}
            onValueChange={(values) => {
                onChange({
                    target: {
                        name: props.name,
                        value: values.value,
                    },
                });
            }}
            format={phone ? defaultUSPhoneFormat : format}
        />
    );
});
