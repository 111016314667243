import { Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles((theme: Theme) => ({
    buttonBar: {
        marginTop: "1rem",
    },
    error: {
        color: theme.palette.error.main,
    },
    fieldBox: {
        marginBottom: "1rem",
    },
    form: {
        marginBottom: "2rem",
        " .form": {
            width: "100%",
        },
    },
    threeColumn: {
        display: "flex",
        justifyContent: "space-between",
        "& .MuiTextField-root": {
            width: "calc(33.33% - 0.5rem)",
        },
    },
}));
