import { useContext, useState } from "react";
import { Navigate } from "react-router-dom";

import { useVerification } from "../../remote/verification";

import { useInterval } from "../../utilities/useInterval";
import { greetingMessage } from "../../utilities/greeter";
import { ProfileContext } from "../../utilities/AuthProvider";

import { Support } from "../../components/Support/Support";
import { BioBank } from "../../components/BioBank/BioBank";
import { Complete } from "../../components/Complete/Complete";
import { KitStatus } from "../../components/KitStatus/KitStatus";
import { Processing } from "../../components/Processing/Processing";
import { UpcomingTests } from "../../components/UpcomingTests/UpcomingTests";
import { ReadinessCard } from "../../components/ReadinessCard/ReadinessCard";
import { ContentLoading } from "../../components/ContentLoading/ContentLoading";

import { useStyles } from "./DashboardStyle";
import { Typography, Alert } from "@mui/material";

export const Dashboard = () => {
    const classes = useStyles();

    const { isProfileLoading, profile, profileRefresh } =
        useContext(ProfileContext);
    const [delay, setDelay] = useState<number | null>(2000);
    const transactionId = sessionStorage.getItem("transactionId");
    const [isProcessing, setProcessing] = useState(!!transactionId);
    const { send } = useVerification();

    // We set the transaction ID in sessionStorage to indicate a just-purchased
    // membership. It can take maybe 3-5 seconds for the membership status to
    // fully update, so we check profile repeatedly until it's done
    const checkStatus = () => {
        if (profile.membershipStatus !== "active" && isProcessing) {
            profileRefresh();
        } else {
            sessionStorage.removeItem("transactionId");
            setProcessing(false);
            setDelay(null);
        }
    };
    useInterval(checkStatus, delay);
    
    if (
        !isProfileLoading &&
        profile.membershipStatus !== "active" &&
        !transactionId
    )
        return <Navigate to={"/checkout-shipping"} />;
    if (isProfileLoading && !isProcessing) return <ContentLoading />;

    const { kitReadiness } = profile;

    const sendVerification = () => {
        send.save({ id: profile.id });
    };

    // temporary
    const hasResult = true;

    return (
        <>
            <Typography variant={"h1"}>
                {profile.kitReadiness.kitReady ? greetingMessage() : `Welcome`}
                {profile.firstName ? `, ${profile.firstName}` : ``}
            </Typography>
            {isProcessing ? (
                <Alert severity="info" className={classes.alert}>
                    Your new membership is processing, this should be quick...
                </Alert>
            ) : (
                <>
                    {kitReadiness.kitReady ? (
                        ``
                    ) : (
                        <>
                            <Typography paragraph>
                                Here's what we need to get your t48 Product
                                shipped:
                            </Typography>
                            <ReadinessCard
                                completed
                                title="Place your order"
                                subheader={{
                                    completed: `Congrats!`,
                                }}
                            />
                            <ReadinessCard
                                completed={kitReadiness.emailVerified}
                                title="Confirm your email address"
                                subheader={{
                                    completed: `Boom! Email verified.`,
                                    incomplete: `Check your inbox. Didn't get it?`,
                                }}
                                func={{
                                    label: `Resend`,
                                    onClick: sendVerification,
                                }}
                            />
                            <ReadinessCard
                                completed={kitReadiness.phoneVerified}
                                title="Verify your phone number"
                                subheader={{
                                    completed: `High five! Phone number verified.`,
                                    incomplete: `This helps us keep your account secure`,
                                }}
                                link={{
                                    label: `Verify`,
                                    path: `/update-phone-number`,
                                }}
                            />
                            <ReadinessCard
                                completed={kitReadiness.profileComplete}
                                title="Complete your profile"
                                subheader={{
                                    completed: `All set! We use this to order your testing.`,
                                    incomplete: `We use this to order your testing`,
                                }}
                                link={{
                                    label: `Update`,
                                    path: `/complete-details`,
                                }}
                            />
                            <ReadinessCard
                                completed={kitReadiness.informedConsentAccepted}
                                title="Review informed consent"
                                subheader={{
                                    completed: `Thanks! We have your responses on file.`,
                                    incomplete: `Sign off on testing for your sample`,
                                }}
                                link={{
                                    label: `Review`,
                                    path: `/informed-consent`,
                                }}
                            />
                        </>
                    )}
                </>
            )}
            {profile.kitReadiness.kitReady ? (
                <>
                    {hasResult ? (
                        <Typography variant={"body1"}>
                            You have test results available.
                        </Typography>
                    ) : (
                        ``
                    )}
                    <KitStatus />
                    <Complete />
                    <Processing />
                    <UpcomingTests />
                    <BioBank />
                    <Support />
                </>
            ) : (
                ``
            )}
        </>
    );
};
