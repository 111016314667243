import { Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles((theme: Theme) => ({
    chip: {
        marginRight: theme.spacing(1),
        color: "#FFFFFF",
        padding: theme.spacing(0, 1),
    },
    isChipSuccess: {
        backgroundColor: theme.palette.success.main,
    },
    isChipWarning: {
        backgroundColor: theme.palette.warning.main,
    },
    listItem: {
        border: `1px solid ${theme.palette.divider}`,
        borderRadius: theme.shape.borderRadius,
    },
}));
