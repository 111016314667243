import { utility } from "../utilities/utility";
import { useGetter } from "../utilities/getter";

export interface iProfile {
    id: string;
    firstName: string;
    lastName: string;
    email: string;
    membershipStatus: string;
    phoneNumber: string;
    image: string;
    birthDate: string;
    sex: string;
    ancestry: string;
    memberSince: string;
    shippingAddress: {
        address: string;
        city: string;
        postalCode: string;
        state: string;
    };
    membership: {
        expiration: string;
        status: string;
        term: number;
    };
    kitReadiness: {
        kitReady: boolean;
        emailVerified: boolean;
        phoneVerified: boolean;
        informedConsentAccepted: boolean;
        profileComplete: boolean;
    };
}

export const defaultProfile: iProfile = {
    id: "",
    firstName: "",
    lastName: "",
    email: "",
    membershipStatus: "never",
    phoneNumber: "",
    image: "",
    birthDate: "",
    sex: "",
    ancestry: "",
    memberSince: "",
    shippingAddress: {
        address: "",
        city: "",
        postalCode: "",
        state: "",
    },
    membership: {
        expiration: "",
        status: "",
        term: 0,
    },
    kitReadiness: {
        kitReady: false,
        emailVerified: false,
        phoneVerified: false,
        informedConsentAccepted: false,
        profileComplete: false,
    },
};

export const useProfile = () => {
    const config = {
        type: "profile",
        endpoint: `profile`,
        authenticate: true,
        list: false,
        // This is a temporary response filter as long as we are demoing
        // Remove once we are properly storing transactions
        responseFilter: (result: Response) => {
            const r = utility.normalizeData(result, defaultProfile);
            if (sessionStorage.getItem("memberStatusOverride") === "yes") {
                r.membershipStatus = "active";
            }
            return r;
        },
    };

    const getter = useGetter(defaultProfile, config);

    return {
        get: getter,
    };
};
