import { useSetter } from "../utilities/setter";

export interface iFormDetails {
    sex: string;
    month: string;
    day: string;
    year: string;
    ancestry: string[];
    unAncestry: string[];
    other: string[];
    unOther: string[];
    otherValue: string;
    preferred: string[];
    unPreferred: string[];
}

export interface iDetails {
    sex: string;
    birthDate: string;
    ancestry: string[];
}

export const defaultDetails: iDetails = {
    sex: "",
    birthDate: "",
    ancestry: [""],
};

export const useDetails = () => {
    const config = {
        type: "details",
        endpoint: `profile/details`,
        authenticate: true,
        list: false,
    };

    const setter = useSetter(defaultDetails, config);

    return {
        set: setter,
    };
};
