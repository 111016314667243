import { useSetter } from "../utilities/setter";

export interface iVerify {
    ticket: string;
}

export const defaultVerify: iVerify = {
    ticket: "",
};

export interface iSend {
    id: string;
}

export const defaultSend: iSend = {
    id: "",
};

export const useVerification = () => {
    const verifyConfig = {
        type: "verificationVerify",
        endpoint: `verification/verify`,
        authenticate: true,
        list: false,
    };

    const sendConfig = {
        type: "verificationSend",
        endpoint: `verification/send`,
        authenticate: true,
        list: false,
    };

    const verify = useSetter(defaultVerify, verifyConfig);
    const send = useSetter(defaultSend, sendConfig);

    return {
        verify: verify,
        send: send,
    };
};
