import { Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles((theme: Theme) => ({
    appBar: {
        zIndex: theme.zIndex.drawer + 1,
    },
    grow: {
        flexGrow: 1,
        paddingTop: `8px`,
    },
    menuButton: {
        [theme.breakpoints.up("lg")]: {
            display: "none",
        },
    },
    mobileIcon: {
        width: "1.563em",
        height: "1.563em",
    },
    sectionDesktop: {},
    toolbar: {
        width: "100%",
        maxWidth: "1140px",
        margin: "0 auto",
    },
}));
