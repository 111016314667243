import { Fab, useScrollTrigger, Zoom } from "@mui/material";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";

import { useStyles } from "./ScrollTopStyles";

export const ScrollTop = () => {
    const classes = useStyles();
    const trigger = useScrollTrigger();

    const handleClick = (event: React.MouseEvent<HTMLDivElement>) => {
        const anchor = (
            (event.target as HTMLDivElement).ownerDocument || document
        ).querySelector("#back-to-top-anchor");

        if (anchor) {
            anchor.scrollIntoView({ behavior: "smooth", block: "center" });
        }
    };

    return (
        <Zoom in={trigger}>
            <div
                onClick={handleClick}
                role="presentation"
                className={classes.zoom}
            >
                <Fab
                    color="primary"
                    size="small"
                    aria-label="Scroll back to top"
                >
                    <KeyboardArrowUpIcon />
                </Fab>
            </div>
        </Zoom>
    );
};
