import { useGetter } from "../utilities/getter";
import { useSetter } from "../utilities/setter";

export interface iGetAncestry {
    name: string;
}

const defaultGetAncestry: iGetAncestry = {
    name: "",
};

export const ancestryList = [
    "Ashkenazi Jewish",
    "Asian",
    "Black/African American",
    "French Canadian",
    "Hispanic",
    "Mediterranean",
    "Native American",
    "Pacific Islander",
    "Sephardic Jewish",
    "White/Caucasian",
];

export const useAncestry = () => {
    const config = {
        type: "ancestry",
        endpoint: `ancestry`,
        authenticate: true,
        list: true,
    };

    const setter = useSetter([], config);
    const getter = useGetter(defaultGetAncestry, config);

    return {
        get: getter,
        set: setter,
    };
};
