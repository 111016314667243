import {
    Box,
    Typography,
    Grid,
    List,
    ListItem,
    ListItemText,
} from "@mui/material";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { useStyles } from "./CompleteStyle";

export const Complete = () => {
    const classes = useStyles();

    // temporary
    const data = [
        {
            title: "Genetic Test",
            value: "We tested for 59 genetic variations",
        },
        {
            title: "Baseline Health Panel",
            value: "Lorem ipsum dolor sit amet",
        },
        {
            title: "You are B+",
            value: "Learn more about your blood type",
        },
    ];

    return (
        <Box mt={4}>
            <Typography variant="subtitle1">Complete</Typography>
            <Grid container spacing={2}>
                {data.map((d) => (
                    <Grid key={d.title} item xs={12}>
                        <List className={classes.list}>
                            <ListItem
                                button
                                key={d.title}
                                className={classes.listItem}
                            >
                                <ListItemText
                                    primary={
                                        <Typography variant="subtitle2">
                                            {d.title}
                                        </Typography>
                                    }
                                    secondary={
                                        <Typography variant="body2">
                                            {d.value}
                                        </Typography>
                                    }
                                />
                                <ArrowForwardIosIcon />
                            </ListItem>
                        </List>
                    </Grid>
                ))}
            </Grid>
        </Box>
    );
};
