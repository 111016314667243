import { Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles((theme: Theme) => ({
    tabs: {
        flexGrow: 1,
        backgroundColor: theme.palette.background.paper,
    },
    alert: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
        "& .MuiAlert-icon": {
            marginTop: "6px",
        },
    },
    completed: {
        backgroundColor: "#EBF2F8",
        "& .MuiAvatar-root": {
            backgroundColor: "#9ABCDC",
        },
        "& .MuiCardHeader-title": {
            textDecoration: "line-through",
        },
    },
}));
