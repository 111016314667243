import React, { useContext, useState } from "react";
import { Auth } from "aws-amplify";

import { useTheme } from "@mui/material/styles";
import { CssBaseline, Toolbar, useMediaQuery } from "@mui/material";

import { ScrollTop } from "../ScrollTop/ScrollTop";
import { BottomNav } from "../BottomNav/BottomNav";
import { CustomAppBar } from "../CustomAppBar/CustomAppBar";
import { CustomDrawer } from "../CustomDrawer/CustomDrawer";

import { useStyles } from "./NavigationStyles";

import { ProfileContext } from "../../utilities/AuthProvider";
import { useAuthenticated } from "../../utilities/useAuthenticated";
interface iProps {
    children: React.ReactNode;
    window?: () => Window;
}

export const Navigation = (props: iProps) => {
    const classes = useStyles();
    const theme = useTheme();
    const { window: wdw } = props;
    const { isAuthenticated } = useAuthenticated();
    const [mobileOpen, setMobileOpen] = useState(false);
    const isSmaller = useMediaQuery(theme.breakpoints.down("sm"));
    const { isProfileLoading, profile } = useContext(ProfileContext);

    const handleUserLogout = () => Auth.signOut();

    return (
        <>
            {isAuthenticated ? (
                <div className={classes.root}>
                    <CssBaseline />
                    <CustomAppBar
                        handleUserLogout={handleUserLogout}
                        setMobileOpen={setMobileOpen}
                    />
                    {isSmaller ? (
                        <CustomDrawer
                            window={wdw}
                            mobileOpen={mobileOpen}
                            handleUserLogout={handleUserLogout}
                            setMobileOpen={setMobileOpen}
                        />
                    ) : (
                        ``
                    )}
                    <main className={classes.content}>
                        <Toolbar id="back-to-top-anchor" />
                        {props.children}
                        <ScrollTop />
                    </main>
                </div>
            ) : (
                <>{props.children}</>
            )}
            {!isProfileLoading && profile.kitReadiness.kitReady ? (
                <BottomNav />
            ) : (
                ``
            )}
        </>
    );
};
