import { Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";

const drawerWidth = 260;

export const useStyles = makeStyles((theme: Theme) => ({
    accountIcon: {
        fontSize: "5rem",
        color: "#757575",
    },
    drawer: {
        width: drawerWidth,
        flexShrink: 0,
    },
    drawerButton: {
        textAlign: "left",
        textTransform: "none",
        justifyContent: "normal",
        fontSize: "1rem",
        fontWeight: 400,
    },
    drawerContainer: {
        overflow: "auto",
    },
    drawerListItem: {
        "&.active .MuiButton-root": {
            color: theme.palette.primary.main,
            fontWeight: 600,
        },
    },
    drawerPaper: {
        width: drawerWidth,
    },
    fw500: {
        fontWeight: 500,
    },
}));
