import { useContext, useEffect, useState } from "react";
import { Navigate, useParams } from "react-router-dom";

import {
    Box,
    Card,
    Table,
    TableBody,
    TableCell,
    TableRow,
    Typography,
} from "@mui/material";
import { useStyles } from "./OrderDetailsStyle";

import { DateTime } from "luxon";
import NumberFormat from "react-number-format";

import { useOrder } from "../../remote/order";
import { useShipping } from "../../remote/shipping";
import { useTaxRate } from "../../remote/taxrate";

import { ContentLoading } from "../../components/ContentLoading/ContentLoading";

import { ProfileContext } from "../../utilities/AuthProvider";

export const OrderDetails = () => {
    const classes = useStyles();

    const { isProfileLoading, profile } = useContext(ProfileContext);
    const { id } = useParams<{ id: string }>();
    const { get } = useOrder(id as string);
    const shipping = useShipping();
    const [postalCode, setPostalCode] = useState("");
    const taxRate = useTaxRate(
        shipping.get?.payload?.shippingAddress?.postalCode
    );
    const taxLabel = taxRate.get.isLoading
        ? ""
        : `(`.concat(taxRate.get.payload.state, ` rate)`);

    useEffect(() => {
        if (shipping.get?.payload?.shippingAddress?.postalCode !== postalCode) {
            setPostalCode(shipping.get.payload.shippingAddress.postalCode);
            taxRate.get.refresh();
        }
    }, [
        postalCode,
        shipping.get.payload.shippingAddress.postalCode,
        taxRate.get,
    ]);

    if (!isProfileLoading && !profile.kitReadiness.kitReady)
        return <Navigate to={"/"} />;
    if (!isProfileLoading && profile.membershipStatus !== "active")
        return <Navigate to={"/checkout-shipping"} />;
    if (isProfileLoading || get.isLoading) return <ContentLoading />;

    const tax = get.payload.items.reduce(
        (a: any, b: any) => a + (b.totals.tax || 0),
        0
    );

    const taxDisplay = taxRate.get.isLoading ? (
        "TBD"
    ) : (
        <NumberFormat
            value={tax}
            displayType={"text"}
            fixedDecimalScale
            decimalScale={2}
            thousandSeparator={true}
            prefix={"$"}
        />
    );
    const totalDisplay = (
        <NumberFormat
            value={get.payload.payment.amount}
            displayType={"text"}
            fixedDecimalScale
            decimalScale={2}
            thousandSeparator={true}
            prefix={"$"}
        />
    );

    return (
        <>
            <Typography variant={"h1"}>Order Details</Typography>
            <Box mt={4}>
                <Typography variant="subtitle2">
                    {DateTime.fromISO(
                        get.payload.purchaseDate as string
                    ).toLocaleString(DateTime.DATE_FULL)}
                </Typography>
                <Card variant="outlined">
                    <Box m={1}>
                        <Typography variant="h5" component="h2">
                            t48 Membership
                        </Typography>
                        <Table size="small" className={classes.table}>
                            <TableBody>
                                {get.payload.items.map((i: any) => (
                                    <TableRow key={i.item.id}>
                                        <TableCell>
                                            {i.item.description}
                                        </TableCell>
                                        <TableCell>
                                            <NumberFormat
                                                value={i.item.price}
                                                displayType={"text"}
                                                fixedDecimalScale
                                                decimalScale={2}
                                                thousandSeparator={true}
                                                prefix={"$"}
                                            />
                                        </TableCell>
                                    </TableRow>
                                ))}
                                <TableRow>
                                    <TableCell>Sales tax {taxLabel}</TableCell>
                                    <TableCell>{taxDisplay}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>Total</TableCell>
                                    <TableCell>{totalDisplay}</TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </Box>
                </Card>
            </Box>
        </>
    );
};
