import { makeStyles } from "@mui/styles";
import { Theme } from "@mui/material";

export const useStyles = makeStyles((theme: Theme) => ({
    button: {
        textTransform: "none",
        minWidth: 0,
    },
    chip: {
        marginLeft: theme.spacing(1),
        color: "#FFFFFF",
        padding: theme.spacing(0, 1),
    },
    isChipSuccess: {
        backgroundColor: theme.palette.success.main,
    },
    isChipWarning: {
        backgroundColor: theme.palette.warning.main,
    },
    flexGrow: {
        flexGrow: 1,
    },
    header: {
        display: "flex",
        alignItems: "center",
        position: "relative",
    },
    title: {
        display: "inline-block",
    },
}));
