import { useContext, useEffect, useState } from "react";
import { NavLink as RouterLink, useLocation } from "react-router-dom";

import { PageLoading } from "@heatherstoneio/heatherstone-react-common";

import { useVerification } from "../../remote/verification";
import { ProfileContext } from "../../utilities/AuthProvider";

import { Alert, Box, Button, Typography } from "@mui/material";

import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";

export const EmailVerification = () => {
    const { isProfileLoading, profileRefresh } = useContext(ProfileContext);
    const qs = useLocation().search;
    const ticket = new URLSearchParams(qs).get("ticket");
    const [initialized, setInitialized] = useState(false);

    const { verify } = useVerification();

    useEffect(() => {
        if (!initialized && !isProfileLoading) {
            verify.save({ ticket: ticket });
            setInitialized(true);
        }
    }, [initialized, verify, ticket, isProfileLoading]);

    const back = () => {
        profileRefresh();
    };

    if (isProfileLoading || verify.isSaving) return <PageLoading />;

    return (
        <>
            <Typography variant={"h1"}>
                {verify.error ? "Email verification failed" : "Email verified"}
            </Typography>
            {verify.error ? (
                <Typography paragraph>
                    Oops, that didn't work. You might have followed a bad link.
                    Would you like to resend your verification email?
                </Typography>
            ) : (
                <Box my={2}>
                    <Alert severity="success">
                        Thanks for verifying your email with t48! This
                        helps us keep your account secure.
                    </Alert>
                </Box>
            )}
            <Button
                variant="contained"
                color="primary"
                onClick={back}
                component={RouterLink}
                to={`/`}
                startIcon={<ArrowBackIosIcon />}
            >
                Back to dashboard
            </Button>
        </>
    );
};
