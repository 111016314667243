export const defaultUSPhoneFormat = "(###) ### ####";

export const defaultPhoneNumberDisplay = (phoneNumber: string) => {
    if (!phoneNumber) return phoneNumber;

    let idx = 0;
    let newPhoneNumber = phoneNumber;

    const formatStringToArray = defaultUSPhoneFormat.split("");
    const phoneStringToArray = newPhoneNumber.split("");

    const resultObj = formatStringToArray.map((w) => {
        if (w === "#") {
            const n = phoneStringToArray[idx];
            idx += 1;
            return n;
        }

        return w;
    });

    return resultObj.join("");
};
