import {
    Box,
    Grid,
    List,
    ListItem,
    ListItemText,
    makeStyles,
    Theme,
    Typography,
} from "@material-ui/core";

const useStyles = makeStyles((theme: Theme) => ({
    list: {
        border: `1px solid ${theme.palette.divider}`,
        borderRadius: theme.shape.borderRadius,
    },
    listItem: {
        paddingTop: 0,
        paddingBottom: 0,
    },
}));

export const Processing = () => {
    const classes = useStyles();

    // temporary
    const data = [
        {
            title: "Baseline Health Panel",
            value: "Your test is in progress, check back soon",
        },
    ];

    return (
        <Box mt={4}>
            <Typography variant="subtitle1">Processing</Typography>
            <Grid container spacing={2}>
                {data.map((d) => (
                    <Grid key={d.title} item xs={12}>
                        <List className={classes.list}>
                            <ListItem
                                button
                                key={d.title}
                                disabled
                                className={classes.listItem}
                            >
                                <ListItemText
                                    primary={
                                        <Typography variant="subtitle2">
                                            {d.title}
                                        </Typography>
                                    }
                                    secondary={
                                        <Typography variant="body2">
                                            {d.value}
                                        </Typography>
                                    }
                                />
                            </ListItem>
                        </List>
                    </Grid>
                ))}
            </Grid>
        </Box>
    );
};
